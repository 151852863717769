import React, { useState, useRef } from 'react'
import Loader from '../../Components/Loader'
import axios from '../../axios';
import { toast } from 'react-toastify';
import { DATEFORMAT } from '../../Custom';
import Switch from "react-switch";

const MerakiAddDiferDetails = (props) => {
    const [loader, setLoader] = useState(false);
    const [Description, setDescription] = useState("");
    const [DescriptionError, setDescriptionError] = useState("");
    const DivRef = useRef(null);
    const CurrentDateTime = DATEFORMAT(new Date());
    const [SelectedDate, setSelectedDate] = useState(CurrentDateTime);
    const [SelectedDateError, setSelectedDateError] = useState("");
    const [AutoUndefer, setAutoUndefer] = useState(false);

    const HandleSubmit = (e) => {
        setDescriptionError("");
        var flag = true;
        if (Description === "") {
            flag = false;
            setDescriptionError("Please add details")
        }
        if (AutoUndefer && SelectedDate === "") {
            flag = false;
            setSelectedDateError("Please select date time for auto undefer")
        }

        if (flag) {
            setLoader(true);
            const TimeZone = localStorage.getItem("TimeZone") ? JSON.parse(localStorage.getItem("TimeZone")) : Intl.DateTimeFormat().resolvedOptions().timeZone
            const TimeZoneParm = (typeof TimeZone === 'string') ? TimeZone : TimeZone.value
            const Payload = {
                "ClientCountryId": props.CountryID,
                "TicketId": props.DeferTicketDate.TicketId,
                "Description": Description,
                "LastTicketStatus": props.DeferTicketDate.TicketStatus,
                "Timezone": TimeZoneParm,
                ...(AutoUndefer && { "AutoUndeferDateTime": SelectedDate })
            }
            axios
                .post(
                    "diferred_meraki_ticket", Payload
                )
                .then((response) => {
                    toast.success(response.data.status.message)
                    props.setServiceCall(true);
                    setLoader(false);
                    props.setDeferTicketDate({})
                })
                .catch((response) => {
                    setLoader(false);
                    props.setDeferTicketDate({})
                });

        }
    }

    const handleCloseClick = (event) => {
        if (DivRef.current && !DivRef.current.contains(event.target)) {
            props.setDeferTicketDate({})
        }
    }

    return (
        <>
            {loader ? <Loader /> : null}
            <div
                className='popup-background'
                onClick={(e) => {
                    handleCloseClick(e)
                }}
            >
                <div
                    className="card"
                    style={{
                        maxWidth: "25%",
                        height: "fit-content",
                        margin: "auto",
                        // textAlign: "center",
                        boxShadow: "0px 0px 5px 0px #5c5c5c",
                    }}
                    ref={DivRef}
                >
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-10 mb-3" style={{ textAlign: "center" }}>
                                <h5 style={{ marginLeft: "68px" }}>Add Defer Details</h5>
                            </div>

                            <div className="col-md-2 text-right">
                                <button className="btn btn-secondary"
                                    onClick={() => {
                                        props.setDeferTicketDate({})
                                    }}
                                >x</button>
                            </div>

                            <div className="col-md-12 mb-3">
                                <div className='row mt-3'>
                                    <div className="col-sm-12">
                                        <textarea placeholder="Add Description"
                                            id="notes" className="form-control" value={Description} name="w3review" rows="4" cols="70" onChange={(e) => {
                                                setDescription(e.target.value);
                                                setDescriptionError("")
                                            }}>
                                        </textarea>
                                        <p className='my-2' style={{ color: "red" }}>{DescriptionError}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 mb-3">
                                <label htmlFor="activated " className="form-label">
                                    Auto Undefer
                                </label>
                                <Switch
                                    className="login-switch m-0 mx-2"
                                    onColor={"#136dfa"}
                                    height={25}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    onChange={() => {
                                        setAutoUndefer(!AutoUndefer)
                                    }}
                                    checked={AutoUndefer}
                                />&nbsp;
                            </div>
                            {
                                AutoUndefer ?

                                    <div className="col-md-12 mb-3">
                                        <input
                                            type="datetime-local"
                                            className="form-control mb-3"
                                            placeholder="Select Date"
                                            value={SelectedDate}
                                            min={CurrentDateTime}
                                            onChange={(e) => {
                                                setSelectedDate(e.target.value)
                                            }}
                                        />
                                        <p className='my-2' style={{ color: "red" }}>{SelectedDateError}</p>
                                    </div>
                                    : null
                            }
                            <div className='col-md-12 mb-3 row'>
                                <div className='col-md-12' style={{ textAlign: "center" }}>
                                    <button title="Back" className="btn btn-primary" style={{ boxShadow: '0px 0px 5px 0px #4f4f4f', borderRadius: '15px', padding: '5px 20px', alignItems: "center" }} onClick={(e) => {
                                        HandleSubmit();
                                    }}>
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MerakiAddDiferDetails