import React, { useState, useEffect, useRef } from 'react'
import axios from "../../axios";
import Loader from "../../Components/Loader";

const ShowTicketUpdate = (props) => {

    const [loader, setLoader] = useState(false);

    const [TicketDescription, setTicketDescription] = useState('');
    const DivRef = useRef(null);

    const handleCloseClick = (event) => {
        if (DivRef.current && !DivRef.current.contains(event.target)) {
            props.setShowTicketUpdateId("");
        }
    };

    useEffect(() => {
        if (props.ShowTicketUpdateId.TicketId && props.CountryID) {
            setLoader(true);
            axios
                .get("get_ticket_update/" + props.ShowTicketUpdateId.TicketId + "?ClientCountryId=" + props.CountryID)
                .then((response) => {
                    setTicketDescription(response.data.data.Description);
                    setLoader(false);
                })
                .catch(() => {
                    setLoader(false);
                });
        }

    }, [props.CountryID, props.ShowTicketUpdateId.TicketId]);

    return (
        <>
            {loader ? <Loader /> : null}
            <div
                className='popup-background'
                onClick={(e) => {
                    handleCloseClick(e)
                }}
            >
                <div
                    className="card"
                    style={{
                        maxWidth: "60%",
                        height: "fit-content",
                        margin: "auto",
                        textAlign: "center",
                        boxShadow: "0px 0px 5px 0px #5c5c5c",

                    }}
                    ref={DivRef}
                >
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-10 text-center mb-3">
                                <h5>Sigfox Ticket ({props.ShowTicketUpdateId.TicketId}) Update </h5>
                            </div>

                            <div className="col-md-2 text-right">
                                <button className="btn btn-secondary"
                                    onClick={() => {
                                        props.setShowTicketUpdateId("");
                                    }}
                                >x</button>
                            </div>

                            <div className="description-popup">
                                {TicketDescription ? TicketDescription : "Ticket update not found."}
                            </div>
                            <div className='col-md-12 mb-3'>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ShowTicketUpdate