import React, { useState, useEffect } from "react";
import axios from "../../axios";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../Components/Loader";
import { ToastContainer, toast } from "react-toastify";
import Switch from "react-switch";
import AddClientCallbackSettings from "./AddClientCallbackSettings";
import Alert from "../../Components/Alert";
import EditClientCallbackSettings from "./EditClientCallbackSettings";
import Curl from "./Curl";

const ClientCallbackSettings = () => {
  const [loader, setLoader] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const [CallbackList, setCallbackList] = useState([]);
  const [ClientId, setClientId] = useState("");
  const [ServiceCall, setServiceCall] = useState(true);
  const [AddFlag, setAddFlag] = useState(false);
  const [Active, setActive] = useState(false);
  const [DeleteUserId, setDeleteUserId] = useState("");
  const [EditUserId, setEditUserId] = useState("");
  const [CurlData, setCurlData] = useState({});

  // Set ClientId from location state if available
  useEffect(() => {
    if (location.state) {
      setClientId(location.state);
    }
  }, [location]);

  // Fetch data based on ClientId and ServiceCall
  useEffect(() => {
    if (ClientId && ServiceCall) {
      setLoader(true);
      Promise.all([
        axios.get(`get_client_callbacks/${ClientId}`),
      ])
        .then(([callbackRes]) => {
          setCallbackList(callbackRes.data.data);
          setLoader(false)
          setServiceCall(false);
        })
        .catch(() => toast.error("Error fetching data"))
        .finally(() => setLoader(false));
    }
  }, [ClientId, ServiceCall]);

  // Handle active status toggle
  const HandleActiveStatus = (item) => {
    const newStatus = parseInt(item.Active) === 1 ? 0 : 1;
    axios
      .put(`change_status_client_callback/${item.Id}`, { Active: newStatus })
      .then((response) => {
        toast.success(response.data.status.message);
        setActive(!Active);
        setServiceCall(true); // Re-trigger data fetching
      })
      .catch(() => toast.error("Error updating active status"))

  };

  const AlertResponse = (response) => {
    if (response === true) {
      setLoader(true);
      axios
        .delete("delete_client_callback_setting/" + DeleteUserId)
        .then((response) => {
          setServiceCall(true);
          setDeleteUserId("");
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
        });
    } else {
      setDeleteUserId("");
    }
  };

  return (
    <>
      <ToastContainer theme="colored" />
      {loader && <Loader />}
      {
        EditUserId ? <EditClientCallbackSettings ClientId={ClientId} setServiceCall={setServiceCall} setEditUserId={setEditUserId} EditUserId={EditUserId} /> : null
      }
      {DeleteUserId ? (
        <Alert
          message="Are you sure, you want to delete this setting?"
          AlertResponse={AlertResponse}
        />
      ) : (
        ""
      )}

      {
        JSON.stringify(CurlData) !== "{}" ?
          <Curl setCurlData={setCurlData} CurlData={CurlData} />
          : null
      }

      {AddFlag && (
        <AddClientCallbackSettings
          setAddFlag={setAddFlag}
          ClientId={ClientId}
          setServiceCall={setServiceCall}
        />
      )}

      <div className="row">
        <div className="col-md-11">
          <h6 className="mb-0">Client Callback Settings</h6>
        </div>
        {/* <div className="col-md-1 text-right">
          <Switch
            className="m-0"
            onColor={"#136dfa"}
            height={25}
            uncheckedIcon={false}
            checkedIcon={false}
            onChange={HandleActiveStatus}
            checked={Active}
          />
        </div> */}
      </div>
      <hr />

      <div className="col-md-12 card">
        {/* <div className="card">
          <div className="card-body">
            <div style={{ maxHeight: "72vh" }} className="horizontal-scroll">
              <table className="table mb-0 table-striped text-center">
                <thead>
                  <tr>
                    <th>#</th>
                    <th style={{ textAlign: "left" }}>Service Provider</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{renderMaintenanceRows()}</tbody>
              </table>
            </div>
          </div>
        </div> */}

        <div className="">
          <div className="row m-3">
            <div className="col-sm-12 text-right">
              <button className="btn btn-primary mx-2" onClick={() => setAddFlag(true)}>
                Add
              </button>
              {/* <Link to={"/clients"}> */}
              <button className="btn btn-secondary" onClick={() => {
                navigate(-1)
              }}>
                Back
              </button>
              {/* </Link> */}
            </div>
          </div>
          <div className="row m-3">
            <div className="col-md-12">
              <table className="table mb-0 table-striped text-center">
                <thead>
                  <tr style={{ textAlign: "left" }}>
                    <th>#</th>
                    <th>Service Provider</th>
                    <th>Create Ticket</th>
                    <th>Update Ticket</th>
                    <th>Curl Request</th>
                    <th>Active</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {CallbackList.length > 0 ? CallbackList.map((item, i) => (
                    <tr key={i} style={{ textAlign: "left" }}>
                      <td style={{
                        verticalAlign: "middle"
                      }}>{i + 1}</td>
                      <td className="" style={{
                        verticalAlign: "middle"
                      }}>{item.CustomSp}</td>
                      <td>
                        <div className="py-1">
                          <strong>Method</strong>: {item.CreateTicket.Method}
                        </div>
                        <div className="py-1">
                          <strong>Url</strong>: {item.CreateTicket.Url}
                        </div>
                        <div className="py-1" style={{ maxWidth: "500px", wordWrap: "break-word" }}>
                          <strong>Headers</strong>: {item.CreateTicket.Headers}
                        </div>
                      </td>
                      <td>
                        <div className="py-1">
                          <strong>Method</strong>: {item.UpdateTicket.Method}
                        </div>
                        <div className="py-1">
                          <strong>Url</strong>: {item.UpdateTicket.Url}
                        </div>
                        <div className="py-1" style={{ maxWidth: "500px", wordWrap: "break-word" }}>
                          <strong>Headers</strong>: {item.UpdateTicket.Headers}
                        </div>
                      </td>
                      <td style={{
                        verticalAlign: "middle"
                      }}>

                        <i className="bx bx-show box-icon-style" title="Show" onClick={() => {
                          setCurlData(item)
                        }}></i>
                      </td>
                      <td style={{
                        verticalAlign: "middle"
                      }}> <Switch
                          className="m-0"
                          onColor={"#136dfa"}
                          height={25}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          onChange={() => {
                            HandleActiveStatus(item)
                          }}
                          checked={item.Active}
                        /></td>
                      <td className="action-buttons" style={{
                        verticalAlign: "middle"
                      }}>
                        <i
                          className="bx bx-trash text-danger"
                          title="Delete"
                          onClick={() => { setDeleteUserId(item.Id); window.scrollTo(0, 0) }}
                        ></i>

                        <i className="bx bx-pencil box-icon-style" title="Edit" onClick={() => {
                          setEditUserId(item.Id)
                        }}></i>

                      </td>

                    </tr>
                  )) :
                    <tr>
                      <td colSpan={7} style={{ textAlign: "center" }}>
                        <b>No Record Found</b>
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientCallbackSettings;
