import React, { useRef } from 'react'
import { useSelector } from 'react-redux'
import { Link } from "react-router-dom";

const EmailBodyView = (props) => {
    const DivRef = useRef(null);

    const getTimeZoneRedux = useSelector((state) => state.TimeZone.value);

    const convert_time_to_timeStamp = (ebody) => {
        const i = ebody.indexOf("##")
        if (i > -1) {
            var timestamp = ebody.slice(i, i + 12)
            var timestampcopy = timestamp
            timestamp = timestamp.replace('##', '')
            timestamp = changeTimeZone(timestamp)
            ebody = ebody.replace(timestampcopy, timestamp)
        }
        return ebody.indexOf("##") > -1 ? convert_time_to_timeStamp(ebody) : ebody
    }

    const changeTimeZone = (getTimeStamp) => {
        const obj = { dateStyle: "medium", timeStyle: "medium" }

        if (localStorage.getItem("TimeZone") !== null) {
            obj['timeZone'] = JSON.parse(localStorage.getItem("TimeZone")).value
                || JSON.parse(getTimeZoneRedux.timezone).value
        }
        return new Date(getTimeStamp * 1000).toLocaleString("en-US", obj)
    }

    const handleCloseClick = (event) => {
        if (DivRef.current && !DivRef.current.contains(event.target)) {
            props.setTicketSelected("");
            props.setEmailBodyFlag(false);
        }
    }

    return (
        <div
            className='popup-background'
            onClick={(e) => {
                handleCloseClick(e)
            }}
        >
            <div
                className="card"
                style={{
                    maxWidth: "50%",
                    height: "fit-content",
                    margin: "auto",
                    textAlign: "center",
                    boxShadow: "0px 0px 5px 0px #5c5c5c",
                }}
                ref={DivRef}
            >
                <div className="card-body horizontal-scroll">
                    <div className="row">
                        <div className="col-md-6 text-left">
                            <h4>Ticket Id : {props.EmailData.TicketId}</h4>
                        </div>
                        <div className="col-md-6 text-right">
                            <button className="btn btn-secondary" onClick={() => {
                                props.setTicketSelected("");
                                props.setEmailBodyFlag(false);
                            }}>x</button>
                        </div>
                        <div className='col-md-12 text-left my-4'>
                            <span style={{ fontWeight: "700" }}>
                                Subject : {" " + props.EmailData.EmailSubject ? props.EmailData.EmailSubject : ""}
                            </span>
                        </div>

                        <div className="col-md-12 text-left">
                            <p style={{ overflowY: 'scroll', maxHeight: '70vh', marginTop: '5px' }}
                                dangerouslySetInnerHTML={{ __html: convert_time_to_timeStamp(props.EmailData.EmailBody) }}
                            >

                            </p>
                            {/* <p>{EmailData.EmailBody}</p> */}
                        </div>

                        {
                            props.TicketsModuleAccess === 2 ? <div className="col-md-12 text-right">
                                <Link to={"/send_email/" + props.EmailData.TicketId} className="btn btn-primary">
                                    <i className="bx bx-arrow-to-right"></i> Forward
                                </Link>
                            </div> : null
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}

export default EmailBodyView