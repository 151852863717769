import React from "react";

const Alert = (props) => {
  return (
    <div
      style={{
        position: "absolute",
        zIndex: 11,
        height: "100vh",
        width: "100%",
        top: -60,
        left: 0,
        display: "flex",
      }}
    >
      <div
        className={props.AssignServiceProvider !== undefined && props.AssignServiceProvider ? "card alert-service-provider" : "card"}

        style={{
          minWidth: "300px",
          height: "fit-content",
          margin: "auto",
          textAlign: "center",
          boxShadow: "0px 0px 5px 0px #5c5c5c",
        }}
      >
        <div className="card-body horizontal-scroll">
          <div className="row">
            {
              props.AssignServiceProvider !== undefined && props.AssignServiceProvider ?
                <div className="col-md-12 text-center my-2" >
                  <h6 style={{ color: "red" }}>Confirmation</h6>
                </div>
                : null
            }
            <div className="col-md-12">
              <p>{props.message}</p>
            </div>
            <div className="col-md-12 text-center">
              <button className="btn btn-success" onClick={() => props.AlertResponse(true)}>Yes</button>
              &nbsp;
              <button className="btn btn-danger" onClick={() => props.AlertResponse(false)}>No</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Alert;
