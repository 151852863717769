import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import axios from "../axios";
import TimeZone from "../TimeZone";
// import { Link } from "react-router-dom";
import Loader from "./Loader";

function TicketDetails() {
    const UrlUata = useParams();

    const [Data, setData] = useState({});
    const [ModuleData, setModuleData] = useState({});
    const [loader, setLoader] = useState(false);
    const [isDataFetched, setIsDataFetched] = useState(false);

    useEffect(() => {
        if (UrlUata.token) {
            const DecodedString = atob(UrlUata.token);
            const ParsedData = JSON.parse(DecodedString);
            setData(ParsedData);
        }
    }, [UrlUata]);


    useEffect(() => {
        if (Object.keys(Data).length > 0 && !isDataFetched) { // Ensure the Data is not empty and API hasn't been called
            if (Data.TicketId && Data.TicketType && Data.ClientCountryId) {
                let Url = "";

                if (Data.TicketType === "Sigfox") {
                    Url = "get_tickets?";
                } else if (Data.TicketType === "Cabisense") {
                    Url = "get_cabisense_tickets?";
                } else if (Data.TicketType === "Meraki") {
                    Url = "get_meraki_tickets?";
                }

                if (Url) {
                    setLoader(true);
                    axios
                        .get(`${Url}ClientCountryId=${Data.ClientCountryId}&TicketId=${Data.TicketId}`)
                        .then((response) => {
                            setModuleData(response.data.data.Tickets.length > 0 ? response.data.data.Tickets[0] : {});
                            setIsDataFetched(true);
                            setLoader(false);
                        })
                        .catch(() => {
                            setIsDataFetched(true);
                            setLoader(false);
                        });
                }
            }
        }
    }, [Data, isDataFetched]);

    return (
        <>
            {loader ? <Loader /> : null}

            <div className="container-fluid" >
                <div className="row d-flex align-items-center p-2">
                    <div className="col-md-6">
                        <div className="page-breadcrumb d-none d-sm-flex align-items-center ">
                            <   div className="breadcrumb-title pe-3">{JSON.stringify(Data) !== "{}" ? Data.TicketType + " " : ""} Ticket Details</div>
                        </div>
                    </div>
                    <div className="col-md-6 d-flex justify-content-end">
                        {/* <Link
                            to={
                                Data && Object.keys(Data).length > 0
                                    ? (
                                        Data.TicketType === "Sigfox"
                                            ? `/tickets-list/${Data.ClientCountryId}`
                                            : (Data.TicketType === "Cabisense"
                                                ? `/cabisense-tickets-list/${Data.ClientCountryId}`
                                                : `/meraki-tickets-list/${Data.ClientCountryId}`)
                                    )
                                    : ""
                            }
                            className="btn btn-primary"
                        >
                            See All Assigned Tickets
                        </Link> */}
                    </div>

                </div>
                <hr />
                {
                    JSON.stringify(ModuleData) !== "{}" ?
                        <div className="row d-flex">

                            <div className="col-sm-7">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="dashboard-client-card custom-p-30 text-center">
                                            <div className="row">
                                                <div className="col-md-4 py-4">

                                                    <h4 className="mb-2">{ModuleData.TicketId}</h4>
                                                    <p className="m-0">Ticket Id</p>

                                                </div>
                                                <div className="col-md-4 my-4">

                                                    <h3 className="mb-2 custom-color-h3">{ModuleData.FreshServiceTicketId}</h3>
                                                    <p className="m-0">Fresh Service Ticket Id</p>

                                                </div>
                                                <div className="col-md-4 my-4">

                                                    <h3 className="mb-2 custom-color-h3">{ModuleData.TicketStatus}</h3>
                                                    <p className="m-0">Ticket Status</p>

                                                </div>
                                                <div className="col-md-4 my-4">

                                                    <h3 className="mb-2 custom-color-h3">{ModuleData.CreatedFrom}</h3>
                                                    <p className="m-0">Created From</p>

                                                </div>
                                                <div className="col-md-4 my-4">

                                                    <h3 className="mb-2 custom-color-h3">{ModuleData.TicketCategory}</h3>
                                                    <p className="m-0">Ticket Category</p>

                                                </div>
                                                <div className="col-md-4 my-4">

                                                    <h3 className="mb-2 custom-color-h3">{ModuleData.IncidentCode}</h3>
                                                    <p className="m-0">Incident Code</p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="dashboard-client-card custom-p-30 text-center">
                                            <div className="row">

                                                <div className="col-md-6 my-4">

                                                    <h3 className="mb-2 custom-color-h3">{(ModuleData.TicketOccuredTime > 0) ? <TimeZone timestamp={ModuleData.TicketOccuredTime} /> : "-"}</h3>
                                                    <p className="m-0">Occurred Time</p>

                                                </div>
                                                <div className="col-md-6 my-4">

                                                    <h3 className="mb-2 custom-color-h3">{(ModuleData.CreatedTimestamp > 0) ? <TimeZone timestamp={ModuleData.CreatedTimestamp} /> : "-"}</h3>
                                                    <p className="m-0">Generated Time</p>

                                                </div>
                                                <div className="col-md-4 my-4">

                                                    <h3 className="mb-2 custom-color-h3">{(ModuleData.PowerConfirmationTimestamp > 0) ? <TimeZone timestamp={ModuleData.PowerConfirmationTimestamp} /> : "-"}</h3>
                                                    <p className="m-0">Power Confirmation Time</p>

                                                </div>
                                                <div className="col-md-4 my-4">

                                                    <h3 className="mb-2 custom-color-h3">{(ModuleData.IncidentDispatchedTimestamp > 0) ? <TimeZone timestamp={ModuleData.IncidentDispatchedTimestamp} /> : "-"}</h3>
                                                    <p className="m-0">Incident Dispatched Time</p>

                                                </div>
                                                <div className="col-md-4 my-4">

                                                    <h3 className="mb-2 custom-color-h3">{(ModuleData.DiferredTimestamp > 0) ? <TimeZone timestamp={ModuleData.DiferredTimestamp} /> : "-"}</h3>
                                                    <p className="m-0">Deferred Time</p>

                                                </div>
                                                <div className="col-md-4 my-4">

                                                    <h3 className="mb-2 custom-color-h3">{(ModuleData.ResolvedTimestamp > 0) ? <TimeZone timestamp={ModuleData.ResolvedTimestamp} /> : "-"}</h3>
                                                    <p className="m-0">Resolved Time</p>

                                                </div>
                                                <div className="col-md-4 my-4">

                                                    <h3 className="mb-2 custom-color-h3">{(ModuleData.ClosedTimestamp > 0) ? <TimeZone timestamp={ModuleData.ClosedTimestamp} /> : "-"}</h3>
                                                    <p className="m-0">Closed Time</p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-5">
                                <div className="row">
                                    <div className={Data.TicketType === "Sigfox" ? "col-md-6" : "col-md-12"}>
                                        <div className="dashboard-client-card custom-p-30 text-center">
                                            <h3 className="mb-2 custom-color-h3">{ModuleData.SiteName}</h3>
                                            <p className="m-0">Site Name</p>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="dashboard-client-card custom-p-30 text-center">
                                            <h3 className="mb-2 custom-color-h3">{ModuleData.DynamicSla}</h3>
                                            <p className="m-0">Dynamic SLA</p>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="dashboard-client-card custom-p-30 text-center">
                                            <h3 className="mb-2 custom-color-h3">{ModuleData.BasestationId}</h3>
                                            <p className="m-0">Basestation Id</p>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="dashboard-client-card custom-p-30 text-center">
                                            <h3 className="mb-2 custom-color-h3">{ModuleData.BasestationLessorId}</h3>
                                            <p className="m-0">Site Lessor Id</p>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="dashboard-client-card custom-p-30 text-center">
                                            <h3 className="mb-2 custom-color-h3">{ModuleData.SiteStatus}</h3>
                                            <p className="m-0">Site Status</p>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="dashboard-client-card custom-p-30 text-center">
                                            <h3 className="mb-2 custom-color-h3">{ModuleData.SiteHost}</h3>
                                            <p className="m-0">Site Host</p>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="dashboard-client-card custom-p-30 text-center">
                                            <h3 className="mb-2 custom-color-h3">{ModuleData.SiteMaintenanceName}</h3>
                                            <p className="m-0">Maintenance</p>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="dashboard-client-card custom-p-30 text-center">
                                            <h3 className="mb-2 custom-color-h3">{ModuleData.Region}</h3>
                                            <p className="m-0">Region</p>
                                        </div>
                                    </div>
                                    {
                                        Data.TicketType === "Sigfox" ?
                                            <div className="col-md-12">
                                                <div className="dashboard-client-card custom-p-30 text-center">
                                                    <h3 className="mb-2 custom-color-h3">{ModuleData.TicketLastUpdates}</h3>
                                                    <p className="m-0">Last Ticket Updates</p>
                                                </div>
                                            </div> : null
                                    }

                                </div>
                            </div>
                        </div>
                        : null
                }
            </div>
        </>
    );
}

export default TicketDetails;
