import React, { useState, useEffect, useRef } from 'react'
import axios from "../../axios";
import Loader from "../../Components/Loader";
import TimeZone from "../../TimeZone";

const CabisenseFaultDetails = (props) => {
    const [loader, setLoader] = useState(false);
    const [FaultDetails, setFaultDetails] = useState([]);
    const DivRef = useRef(null);

    useEffect(() => {
        if (props.FaultDetailsId) {
            setLoader(true);
            const payload = {
                'FaultReference': props.FaultDetailsId,
                'ClientCountryId': props.CountryID,
            }
            axios
                .post(
                    "get_cabisense_fault_updates", payload
                )
                .then((response) => {
                    setFaultDetails(response.data.data);
                    setLoader(false);
                })
                .catch(() => {
                    setLoader(false);
                });
        }

    }, [props.FaultDetailsId, props.CountryID])


    const handleCloseClick = (event) => {
        if (DivRef.current && !DivRef.current.contains(event.target)) {
            props.setFaultDetailsId("")
        }
    }

    return (
        <>
            {loader ? <Loader /> : null}
            <div
                className='popup-background'
                onClick={(e) => {
                    handleCloseClick(e)
                }}
            >
                <div
                    className="card"
                    style={{
                        maxWidth: "60%",
                        height: "fit-content",
                        margin: "auto",
                        textAlign: "center",
                        boxShadow: "0px 0px 5px 0px #5c5c5c",
                    }}
                    ref={DivRef}

                >

                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-11 text-left mb-3">
                                <h5>Faults Updates</h5>
                            </div>

                            <div className="col-md-1 text-right">
                                <button className="btn btn-secondary"
                                    onClick={() => {
                                        props.setFaultDetailsId("")
                                    }}
                                >x</button>
                            </div>

                            <div className="col-md-12 mb-3  horizontal-scroll">
                                <table className="table mb-0 table-striped ticket-table">
                                    <thead>
                                        <tr>
                                            <th style={{ width: "70px" }}>#</th>
                                            <th>FieldStaff Name</th>
                                            <th>FaultReference</th>
                                            <th>Latitude</th>
                                            <th>Longitude</th>
                                            <th>Location Name</th>
                                            <th>Severity</th>
                                            <th>Status</th>
                                            <th>External Reference</th>
                                            <th>CallerIncident Code</th>
                                            <th>Classification</th>
                                            <th>FieldIncident Code</th>
                                            <th>Completion Code</th>
                                            <th>DateTime</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {FaultDetails.length > 0 ?
                                            FaultDetails.map((item, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>{i + 1}</td>
                                                        <td>{item.FieldStaffName}</td>
                                                        <td>{item.FaultReference}</td>
                                                        <td>{item.Latitude}</td>
                                                        <td>{item.Longitude}</td>
                                                        <td>{item.LocationName}</td>
                                                        <td>{item.Severity}</td>
                                                        <td>{item.Status}</td>
                                                        <td>{item.ExternalReference}</td>
                                                        <td>{item.CallerIncidentCode}</td>
                                                        <td>{item.Classification}</td>
                                                        <td>{item.FieldIncidentCode}</td>
                                                        <td>{item.Completion_Code !== "null" ? item.Completion_Code : ""}</td>
                                                        <td>{(item.DateTimestamp) ? <TimeZone timestamp={item.DateTimestamp} /> : ""}</td>
                                                    </tr>
                                                );
                                            }) :
                                            <tr>
                                                <td
                                                    colSpan={14}
                                                    style={{ textAlign: "center" }}>
                                                    <b>No Record Found</b>
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className='col-md-12 mb-3'>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CabisenseFaultDetails