import React, { useRef } from 'react'
import TimeZone from "../../TimeZone";

const BasestationDetails = (props) => {
    const DivRef = useRef(null);

    const HandleClose = (event) => {
        if (DivRef.current && !DivRef.current.contains(event.target)) {
            props.setBasestationsTestData([])
        }
    }

    return (
        <div
            className='popup-background'
            onClick={(e) => {
                HandleClose(e)
            }}
        >
            <div
                className="card"
                style={{
                    width: "90%",
                    height: "fit-content",
                    margin: "auto",
                    textAlign: "center",
                    boxShadow: "0px 0px 5px 0px #5c5c5c",
                    maxHeight: '85vh'
                }}
                ref={DivRef}
            >
                <div className="card-body horizontal-scroll">
                    <i className="bx bx-x" title="Close" style={{ position: 'absolute', right: '20px', top: '5px', cursor: 'pointer', fontSize: '23px', fontWeight: 'bold' }} onClick={() => props.setBasestationsTestData([])}></i>
                    <div className="row">
                        <div className="col-md-12">
                            <h5>{props.BasestationsTestName}</h5>
                        </div>
                        <div className="col-md-12 text-center">
                            <table className="table mb-0 table-striped">
                                <thead>
                                    <tr>
                                        <th>State</th>
                                        <th>Test Type</th>
                                        <th>Severity</th>
                                        <th>Last Success Time</th>
                                        <th>Last Failure Time</th>
                                        <th>Last Updated Time</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        props.BasestationsTestData.map((item) => {
                                            return (
                                                <tr key={item.Id} className={(item.Status === 'KO' ? 'ko-bg' : '')}>
                                                    <th>{item.Status}</th>
                                                    <th>{item.TestType}</th>
                                                    <th>{item.Severity}</th>
                                                    <th>{item.LastSuccessTimeStamp > 0 ? <TimeZone timestamp={item.LastSuccessTimeStamp} /> : null}</th>
                                                    <th>{item.LastFailureTimeStamp > 0 ? <TimeZone timestamp={item.LastFailureTimeStamp} /> : null}</th>
                                                    <th>{item.LastUpdatedTimestamp > 0 ? <TimeZone timestamp={item.LastUpdatedTimestamp} /> : null}</th>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BasestationDetails