import React, { useRef } from 'react'
import TimeZone from "../../TimeZone";


const ImpactedDeviceDownAlert = (props) => {

    const DivRef = useRef(null);


    const handleCloseClick = (event) => {
        if (DivRef.current && !DivRef.current.contains(event.target)) {
            props.setImpactedDeviceDownAlertFlag(false)
        }
    }

    return (
        <>
            <div
                className='popup-background'
                onClick={(e) => {
                    handleCloseClick(e)
                }}
            >

                <div className="row w-100 d-flex align-items-center justify-content-end">
                    <div className="col-sm-5">
                        <div
                            className="card"
                            style={{
                                height: "fit-content",
                                margin: "auto",
                                textAlign: "center",
                                boxShadow: "0px 0px 5px 0px #5c5c5c",
                                maxHeight: '85vh',

                            }}

                            ref={DivRef}
                        >

                            <div className="card-body  mt-4">
                                <i className="bx bx-x" title="Close" style={{ position: 'absolute', right: '20px', top: '5px', cursor: 'pointer', fontSize: '23px', fontWeight: 'bold' }} onClick={(e) => {
                                    props.setImpactedDeviceDownAlertFlag(false)
                                }}></i>

                                <div className="row">
                                    <div className="col-md-12 text-center mt-1">
                                        <h6>Impacted Device Down Alerts</h6>
                                    </div>

                                    <div className="col-md-12 text-center mt-3" style={{ overflow: "auto", height: "570px" }}>

                                        <table className="table mb-0 table-striped ticket-table ">
                                            <thead>
                                                <tr>
                                                    {/* <th style={{ width: "70px" }}>#</th> */}
                                                    <th>Channel Partner</th>
                                                    <th>No. of Impacted Devices </th>

                                                    <th>Generated Time</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {props.ImpactedDeviceDownAlertData.length > 0 ?
                                                    props.ImpactedDeviceDownAlertData.map((item, i) => {
                                                        return (
                                                            <tr key={i} style={{ textAlign: "center" }}>
                                                                {/* <td>{i + 1}</td> */}
                                                                <td>{item.ChannelPartner}</td>
                                                                <td>{item.NoOfImpactedDevice}</td>

                                                                <td>{item.CreatedAt > 0 ? <TimeZone timestamp={item.CreatedAt} /> : 0}</td>
                                                            </tr>
                                                        );
                                                    }) :
                                                    <tr>
                                                        <td
                                                            colSpan={8}
                                                            style={{ textAlign: "center" }}>
                                                            <b>No Record Found</b>
                                                        </td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </>
    )
}

export default ImpactedDeviceDownAlert