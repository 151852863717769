import React, { useState, useEffect, useRef } from 'react'
import axios from "../../axios";
import TimeZone from "../../TimeZone";
import Loader from "../../Components/Loader";

const DiferDetails = (props) => {
    const [loader, setLoader] = useState(false);
    const [ModuleData, setModuleData] = useState([]);
    const DivRef = useRef(null);

    useEffect(() => {
        if (props.DiferDetailsId) {
            setLoader(true);
            axios
                .get(
                    "get_deferred_bs_states?TicketId=" + props.DiferDetailsId + "&ClientCountryId=" + props.CountryID
                )
                .then((response) => {
                    setModuleData(response.data.data);
                    setLoader(false);
                })
                .catch(() => {
                    setLoader(false);
                });
        }

    }, [props.DiferDetailsId, props.CountryID])

    const handleCloseClick = (event) => {
        if (DivRef.current && !DivRef.current.contains(event.target)) {
            props.setDiferDetailsId("")
        }
    }

    return (
        <>
            {loader ? <Loader /> : null}
            <div
                className='popup-background'
                onClick={(e) => {
                    handleCloseClick(e)
                }}
            >
                <div
                    className="card"
                    style={{
                        maxWidth: "60%",
                        height: "fit-content",
                        margin: "auto",
                        textAlign: "center",
                        boxShadow: "0px 0px 5px 0px #5c5c5c",
                    }}
                    ref={DivRef}

                >
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-11 mb-3">
                                <h5>Sigfox Deferred Details</h5>
                            </div>

                            <div className="col-md-1 text-right">
                                <button className="btn btn-secondary"
                                    onClick={() => {
                                        props.setDiferDetailsId("")
                                    }}
                                >x</button>
                            </div>

                            <div className="col-md-12 mb-3  horizontal-scroll">
                                <table className="table mb-0 table-striped ticket-table">
                                    <thead>
                                        <tr>
                                            <th style={{ width: "70px" }}>#</th>
                                            <th>Description</th>
                                            <th>Last Ticket Status</th>
                                            <th>Created By</th>
                                            <th>Start Date</th>
                                            <th>End Date</th>
                                            <th>Auto Undefer Date Time</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {ModuleData.length > 0 ?
                                            ModuleData.map((item, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>{i + 1}</td>
                                                        <td>{item.Description}</td>
                                                        <td>{item.LastTicketStatus}</td>
                                                        <td>{item.CreatedUser}</td>
                                                        <td>{item.StartDatetime > 0 ? <TimeZone timestamp={item.StartDatetime} /> : 0}</td>
                                                        <td>{item.EndDateTime > 0 ? <TimeZone timestamp={item.EndDateTime} /> : 0}</td>
                                                        <td>{item.AutoUndeferTimestamp > 0 ? <TimeZone timestamp={item.AutoUndeferTimestamp} /> : "-"}</td>
                                                    </tr>
                                                );
                                            }) :
                                            <tr>
                                                <td
                                                    colSpan={7}
                                                    style={{ textAlign: "center" }}>
                                                    <b>No Record Found</b>
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className='col-md-12 mb-3'>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DiferDetails