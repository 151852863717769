import React, { useEffect, useState } from "react";
import axios from "../../axios";
import { Link } from "react-router-dom";
import Loader from "../../Components/Loader";
import Alert from "../../Components/Alert";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router";
import Switch from "react-switch";

const RoleList = () => {
  const location = useLocation();
  const [ModuleData, setModuleData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [ServiceCall, setServiceCall] = useState(true);
  const [DeleteUserId, setDeleteUserId] = useState("");
  const [RoleAccessId, setRoleAccessId] = useState("");
  const [ModuleAccessData, setMuduleAccessData] = useState([]);
  const [RoleName, setRoleName] = useState("");
  const [search, setSearch] = useState("")

  useEffect(() => {
    if (location.state) {
      setSuccess(location.state);
      location.state = null;
    }
  }, [location]);

  const [success, setSuccess] = useState("");
  useEffect(() => {
    if (success) {
      toast.success(success);
      setSuccess("");
    }
    return () => { };
  }, [success]);

  useEffect(() => {
    if (ServiceCall) {
      setLoader(true);
      axios
        .get("roles?Search=" + search)
        .then((response) => {
          setModuleData(response.data.data);
          setServiceCall(false);
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
        });
    }
  }, [ServiceCall, search]);

  const AlertResponse = (response) => {
    if (response === true) {
      setLoader(true);
      axios
        .delete("role/" + DeleteUserId)
        .then((response) => {
          setServiceCall(true);
          setDeleteUserId("");
          setSuccess("Role deleted successfully");
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
        });
    } else {
      setDeleteUserId("");
    }
  };

  useEffect(() => {
    if (RoleAccessId) {
      setLoader(true);
      axios
        .get("get_user_role_access?RoleId=" + RoleAccessId)
        .then((response) => {
          setMuduleAccessData(response.data.data);
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
        });
    }
  }, [RoleAccessId]);

  const handleAccess = (moduleId, value, modules = ModuleAccessData) => {
    const updateAccess = (modules) => {
      return modules.map((module) => {
        if (module.Id === moduleId) {
          return { ...module, Access: value };
        } else if (module.SubModules && module.SubModules.length > 0) {
          return { ...module, SubModules: updateAccess(module.SubModules) };
        } else {
          return module;
        }
      });
    };

    const updatedModules = updateAccess(modules);
    setMuduleAccessData(updatedModules);
  };

  function handleArrayRecursively(ModuleAccessData) {
    return ModuleAccessData.map(module => {
      const newModule = {
        ModuleID: module.Id,
        Access: module.Access,
      };

      if ('SubModules' in module && Array.isArray(module.SubModules) && module.SubModules.length > 0) {
        newModule.SubModules = handleArrayRecursively(module.SubModules);
      }

      return newModule;
    });
  }

  const formSubmit = (e) => {
    e.preventDefault();
    const data = handleArrayRecursively(ModuleAccessData);
    setLoader(true);

    axios
      .put("save_user_role_access", {
        RoleId: RoleAccessId,
        Modules: data,
      })
      .then((response) => {
        setMuduleAccessData(response.data.data);
        setSuccess(response.data.status.message);
        setLoader(false);
        setRoleAccessId("");
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const handleSubmit = () => {
    setServiceCall(true)
  }

  const handleClearSearch = () => {
    setSearch("")
    setServiceCall(true)
  }

  const ChangeState = (item) => {
    const payload = {
      RoleName: item.RoleName,
      UserGroupId: item.UserGroupId,
      Active: item.Active === 0 ? 1 : 0,
    }
    axios
      .put("role/" + item.Id, payload)
      .then((response) => {
        setServiceCall(true);
      })
      .catch(() => {
      });
  }

  const ExportRole = () => {
    setLoader(true)
    let url = "exportroles?Search=" + search
    axios.get(url)
      .then((response) => {
        var link = document.createElement("a");
        link.target = "_blank";
        link.href = response.data.data;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoader(false)
        setSuccess(response.data.status.message)
      })
      .catch((error) => {
        setLoader(false)
      })
  }

  const ExportRoleAccess = () => {
    if (RoleAccessId) {
      setLoader(true)
      let url = "exportroleaccess?RoleId=" + RoleAccessId
      axios.get(url)
        .then((response) => {
          var link = document.createElement("a");
          link.target = "_blank";
          link.href = response.data.data;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setLoader(false)
          setSuccess(response.data.status.message)
        })
        .catch((error) => {
          setLoader(false)
        })
    }
  }

  const RenderModules = (Modules) => {
    return Modules.map((item, index) => (

      <React.Fragment key={item.Id}>
        <div className="card role-access-card">
          <div className="row mx-2 my-2">
            <div className="col-md-6"><div style={{
              marginLeft: item.Level * 20 + "px"
            }}>{index + 1}. {item.ModuleName}</div></div>
            {item.SubModules ?
              <div className="col-md-6"></div>
              :
              <>
                <div className="col-md-2">
                  <input
                    type="radio"
                    name={item.Id + item.ModuleName}
                    value={0}
                    checked={0 === parseInt(item.Access)}
                    onChange={() => handleAccess(item.Id, 0)}
                    className="cursor-pointer"
                  />
                  &nbsp;
                  None
                </div>
                <div className="col-md-2">

                  <input
                    type="radio"
                    name={item.Id + item.ModuleName}
                    value={1}
                    checked={1 === parseInt(item.Access)}
                    onChange={() => handleAccess(item.Id, 1)}
                    className="cursor-pointer"
                  />
                  &nbsp;
                  Read
                </div>
                <div className="col-md-2">

                  <input
                    type="radio"
                    name={item.Id + item.ModuleName}
                    value={2}
                    checked={2 === parseInt(item.Access)}
                    onChange={() => handleAccess(item.Id, 2)}
                    className="cursor-pointer"
                  />
                  &nbsp;
                  Write
                </div>
              </>
            }
          </div>
        </div>
        {item.SubModules && RenderModules(item.SubModules)}
      </React.Fragment>
    ));
  };


  return (
    <>
      <ToastContainer theme="colored" />
      {loader ? <Loader /> : null}
      {DeleteUserId ? (
        <Alert
          message="Are you sure, you want to delete this role?"
          AlertResponse={AlertResponse}
        />
      ) : (
        ""
      )}
      <div className="row">
        <div className="col-md-8">
          <h6 className="mb-0">Roles List</h6>
        </div>
        <div className="col-md-4 text-right">
          <Link to="/add_role" className="btn btn-primary">
            <i className="bx bx-plus"></i> Add Role
          </Link>
        </div>
      </div>
      <hr />

      <div className="row">
        <div className="col-sm-3">
          <input
            type="text"
            className="form-control mb-3"
            id="search"
            name="search"
            placeholder="Search Here"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div className="col-sm-3">
          <button
            type="submit"
            className="btn btn-primary mb-3"
            title="Search"
            onClick={handleSubmit}
          >
            <i className="bx bx-search search-icon"></i>
          </button>
          &nbsp;
          {search ?
            <button
              type="button"
              className="btn btn-primary mb-3"
              title="Clear"
              onClick={handleClearSearch}
            >
              <i className="bx bx-eraser search-icon"></i>
            </button>
            : null}
        </div>
        {!RoleAccessId ?
          <div className="col-sm-6 text-right">
            <button onClick={() => ExportRole()} type="button" title="Export" className="btn btn-primary" style={{ boxShadow: '0px 0px 5px 0px #4f4f4f', borderRadius: '15px', padding: '5px 20px' }}>Export</button>
          </div> :
          <div className="col-sm-6 text-right">
            <button onClick={() => ExportRoleAccess()} type="button" title="Export" className="btn btn-primary" style={{ boxShadow: '0px 0px 5px 0px #4f4f4f', borderRadius: '15px', padding: '5px 20px' }}>Export Role Access</button>
          </div>}
      </div>

      <div className="row">
        {RoleAccessId ? (
          <div className="col-xl-10 mx-auto">
            <div className="card">
              <div className="card-body" >
                <div className="card-title">
                  <div className="row">
                    <div className="col-md-8">
                      <h5 className="mb-0 text-uppercase text-primary">
                        <i className="bx bx-shield-x me-1 font-22 "></i>
                        {RoleName}
                      </h5>
                    </div>
                    <div className="col-md-4 text-right">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => {
                          setRoleAccessId("");
                          setRoleName("");
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
                <hr />

                <form onSubmit={formSubmit}>
                  {RenderModules(ModuleAccessData)}
                  <div className="col-12">
                    <button
                      type="submit"
                      className="btn btn-primary px-5"
                      style={{ marginTop: "20px", float: 'right' }}
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        ) : (
          <div className={"col-md-12"}>
            <div className="card">
              <div className="card-body">
                <table className="table mb-0 table-striped ticket-table">
                  <thead style={{ zIndex: "9" }}>
                    <tr>
                      <th>#</th>
                      <th>Rolename</th>
                      <th>Active</th>
                      <th>Access</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ModuleData.length > 0 ?
                      ModuleData.map((item, index) => {
                        return (
                          <tr key={item.Id + index}>
                            <td>{index + 1}</td>
                            <td>{item.RoleName}</td>
                            <td>
                              <Switch
                                className="login-switch"
                                onColor={"#136dfa"}
                                height={25}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                onChange={() => ChangeState(item)}
                                checked={item.Active === 1 ? true : false}
                              />
                            </td>
                            <td>
                              <i
                                onClick={() => {
                                  setRoleAccessId(item.Id);
                                  setRoleName(item.RoleName);
                                }}
                                className="bx bx-shield-x box-icon-style"
                                style={{ fontSize: "20px" }}
                              ></i>
                            </td>
                            <td className="action-buttons">
                              <i
                                className="bx bx-trash text-danger"
                                title="Delete"
                                onClick={() => {
                                  setDeleteUserId(item.Id);
                                  window.scrollTo(0, 0)
                                }}
                              ></i>
                              <Link to={"/add_role/" + item.Id}>
                                <i className="bx bx-pencil" title="Edit"></i>
                              </Link>
                            </td>
                          </tr>
                        );
                      })
                      :
                      <tr>
                        <td
                          colSpan={6}
                          style={{ textAlign: "center" }}>
                          <b>No Record Found</b>
                        </td>
                      </tr>}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default RoleList;
