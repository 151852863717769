import React, { useState, useRef } from 'react'
import axios from '../axios'
import { toast } from 'react-toastify'
import Alert from './Alert'

const AddCustomSp = (props) => {
    const [SelectedCustomSp, setSelectedCustomSp] = useState(props.CustomSpData.CustomSpId ? props.CustomSpData.CustomSpId : 0)
    const [SelectedCustomSpError, setSelectedCustomSpError] = useState("")
    const [ValidationFlag, setValidationFlag] = useState(false);

    const DivRef = useRef(null);

    const HandleSubmit = (e) => {
        setSelectedCustomSpError("");
        var flag = true;

        if (parseInt(SelectedCustomSp) === 0) {
            flag = false;
            setSelectedCustomSpError("Please select service provider.")
        }

        if (flag) {
            setValidationFlag(true);
        }
    }

    const handleAlertResponse = (response) => {
        if (response && props.CustomSpData.TicketId && props.CountryID && SelectedCustomSp) {
            props.setLoader(true);
            var Url = "";
            if (props.Flag === "Sigfox") {
                Url = "assign_customsp_to_ticket/";
            } else if (props.Flag === "Cabisense") {
                Url = "assign_customsp_to_cabisense_ticket/"
            } else if (props.Flag === "Meraki") {
                Url = "assign_customsp_to_meraki_ticket/"
            }
            axios
                .put(
                    Url + props.CustomSpData.TicketId, {
                    "ClientCountryId": props.CountryID,
                    "CustomSpId": SelectedCustomSp
                }
                )
                .then((response) => {
                    toast.success(response.data.status.message)
                    props.setServiceCall(true);
                    setValidationFlag(false)
                    props.setLoader(false);
                    props.setCustomSpData({})
                })
                .catch((response) => {
                    props.setLoader(false);
                    props.setCustomSpData({})
                });
        } else {
            setValidationFlag(false)
        }
    }

    const handleCloseClick = (event) => {
        if (DivRef.current && !DivRef.current.contains(event.target)) {
            props.setCustomSpData({})
        }
    }

    return (
        <>
            <div
                className='popup-background'
                onClick={(e) => {
                    handleCloseClick(e)
                }}
            >
                {ValidationFlag ?
                    <Alert
                        message="Are you sure you want to assign a service provider to the ticket? If you assign a service provider, the selected ticket's status will change to 'Incident Dispatched'."
                        AlertResponse={handleAlertResponse}
                        AssignServiceProvider={true}
                    />
                    :
                    null
                }

                <div
                    className="card"
                    style={{
                        maxWidth: "30%",
                        height: "fit-content",
                        margin: "auto",
                        // textAlign: "center",
                        boxShadow: "0px 0px 5px 0px #5c5c5c",
                    }}
                    ref={DivRef}
                >
                    <div className="card-body">
                        <div className="row px-2">
                            <div className="col-md-10 mb-3" style={{ textAlign: "center" }}>
                                <h5 style={{ marginLeft: "68px" }}>Add Service Provider ( {props.CustomSpData.TicketId} )</h5>
                            </div>

                            <div className="col-md-2 text-right">
                                <button className="btn btn-secondary"
                                    onClick={() => {
                                        props.setCustomSpData({})
                                    }}
                                >x</button>
                            </div>

                            <div className="col-md-12 mb-3">
                                <div className='row mt-3'>
                                    <div className="col-sm-12">
                                        <select
                                            className="form-select"
                                            onChange={(e) => {
                                                setSelectedCustomSp(e.target.value)
                                            }}
                                            value={SelectedCustomSp}
                                        >
                                            <option value="0">Select Service Provider</option>
                                            {
                                                props.CustomSpList.map((item, index) => (
                                                    <option key={index} value={item.Id}> {item.CustomSp} </option>
                                                ))
                                            }
                                        </select>
                                        <p className='my-2' style={{ color: "red" }}>{SelectedCustomSpError}</p>
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-12 mb-3 row'>
                                <div className='col-md-12' style={{ textAlign: "center" }}>
                                    <button title="Back" className="btn btn-primary" style={{ boxShadow: '0px 0px 5px 0px #4f4f4f', borderRadius: '15px', padding: '5px 20px', alignItems: "center" }} onClick={(e) => {
                                        HandleSubmit();
                                    }}>
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddCustomSp