import React, { useEffect, useRef, useState } from 'react'
import ReactCountryFlag from "react-country-flag"

const CronStatus = (props) => {
    const DivRef = useRef(null);
    const [CronData, setCronData] = useState({});
    const [SelectedCountry, setSelectedCountry] = useState("");
    const [SelectedCountryId, setSelectedCountryId] = useState("");
    const [SelectedCountryFlag, setSelectedCountryFlag] = useState("");

    useEffect(() => {
        if (JSON.stringify(props.CronStatusData) !== "{}") {
            var Obj = {};
            for (const property in props.CronStatusData) {
                const sortedData = props.CronStatusData[property].sort((a, b) => a.SortOrder - b.SortOrder);
                Obj[property] = sortedData
            }
            setCronData(Obj);
        }

    }, [props.CronStatusData])

    useEffect(() => {
        if (props.AllCountries.length > 0 && props.CronStatusClientCountryId === "" && props.SelectedCronStatusPage === "client") {
            props.setCronStatusClientCountryId(props.AllCountries[0].Id)
        }

    }, [props])

    useEffect(() => {

        if (props.SelectedCronStatusPage === "country" && props.AllCountries.length > 0 && props.CronStatusClientCountryId !== "") {
            for (let i = 0; i < props.AllCountries.length; i++) {
                if (props.CronStatusClientCountryId === props.AllCountries[i].Id) {
                    setSelectedCountryId(props.AllCountries[i].Id)
                    setSelectedCountry(props.AllCountries[i].CountryName)
                    setSelectedCountryFlag(props.AllCountries[i].CountryCode)
                }
            }
        }

    }, [props])

    const HandleClose = (event) => {
        if (DivRef.current && !DivRef.current.contains(event.target)) {
            props.setCronStatusClientId("")
            props.setCronStatusClientCountryId("")
        }
    }

    return (
        <>
            <div
                className='popup-background'
                onClick={(e) => {
                    HandleClose(e)
                }}
            >
                <div
                    className="card"
                    style={{
                        width: "90%",
                        height: "fit-content",
                        margin: "auto",
                        textAlign: "center",
                        boxShadow: "0px 0px 5px 0px #5c5c5c",
                        maxHeight: '85vh'
                    }}
                    ref={DivRef}
                >
                    <div className="card-body mt-4">
                        <i className="bx bx-x" title="Close" style={{ position: 'absolute', right: '20px', top: '5px', cursor: 'pointer', fontSize: '23px', fontWeight: 'bold' }} onClick={(e) => {
                            props.setCronStatusClientId("")
                            props.setCronStatusClientCountryId("")
                        }}></i>

                        <div className="p-2 gap-3" style={{ display: '-webkit-box', padding: '0px 10px' }}>

                            {
                                props.SelectedCronStatusPage === "client" ?

                                    props.AllCountries.length > 0 ?
                                        props.AllCountries.map((item, i) => {
                                            return (
                                                <div className="gap-4 px-2 py-1"
                                                    style={{
                                                        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 8px 0px',
                                                        borderRadius: '6px',
                                                        border: parseInt(item.Id) === parseInt(props.CronStatusClientCountryId) ? '1px solid #0d6efd' : ""
                                                    }}
                                                    key={i} onClick={() => {
                                                        props.setCronStatusClientCountryId(item.Id)
                                                    }}>
                                                    <div className="d-flex align-items-center gap-1">
                                                        <p className="m-0">
                                                            <ReactCountryFlag
                                                                className="mx-1"
                                                                countryCode={item.CountryCode}
                                                                aria-label={item.CountryCode}
                                                                style={{
                                                                    lineHeight: "0em",
                                                                    fontSize: "1.4em"
                                                                }}
                                                            />
                                                        </p>
                                                        <p className="m-0">{item.CountryName}</p>
                                                    </div>
                                                </div>
                                            );
                                        })
                                        : null

                                    :

                                    <div className="gap-4 px-2 py-1" style={{ boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 8px 0px', borderRadius: '6px', border: '1px solid #0d6efd' }} onClick={() => {
                                        props.setCronStatusClientCountryId(SelectedCountryId)
                                    }}>
                                        <div className="d-flex align-items-center gap-1">
                                            <p className="m-0">
                                                <ReactCountryFlag
                                                    className="mx-1"
                                                    countryCode={SelectedCountryFlag}
                                                    aria-label={SelectedCountryFlag}
                                                    style={{
                                                        lineHeight: "0em",
                                                        fontSize: "1.4em"
                                                    }}
                                                />
                                            </p>
                                            <p className="m-0">{SelectedCountry}</p>
                                        </div>
                                    </div>
                            }


                        </div>
                        <hr />

                        {
                            JSON.stringify(CronData) !== "{}" && props.CronStatusClientCountryId !== "" ?
                                <div className='row pt-2' style={{
                                    height: "500px",
                                    overflow: "auto"
                                }}>

                                    {
                                        CronData.SigfoxSync !== undefined && CronData.SigfoxSync.length > 0 ?
                                            <div className='col-sm-4'>
                                                <div className="card">
                                                    <div className="card-header py-3 justify-content-center align-items-center gap-2 cron-status-card-header" >
                                                        {/* <div className="custom-indicator indicator-green" ></div> */}
                                                        <h5 className='m-0'>Sigfox Sync</h5>
                                                    </div>

                                                    <div className='row justify-content-center p-3'>
                                                        {
                                                            CronData.SigfoxSync.map((item, i) => {
                                                                return (
                                                                    <div className='col-sm-6' key={i}>
                                                                        <div className={"card p-3 my-3 custom-indicator-card gap-2 indicator-" + item.IndicatorFlag + "-bg"}>
                                                                            <div className={"custom-indicator indicator-" + item.IndicatorFlag} ></div>
                                                                            <p className='m-0'>{item.DisplayName}</p>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })

                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            : null
                                    }

                                    {CronData.TicketSync !== undefined && CronData.TicketSync.length > 0 ?
                                        <div className='col-sm-4'>
                                            <div className="card">
                                                <div className="card-header py-3 justify-content-center align-items-center gap-2 cron-status-card-header" >
                                                    {/* <div className="custom-indicator indicator-green" ></div> */}
                                                    <h5 className='m-0'>Tickets Sync</h5>
                                                </div>

                                                <div className='row justify-content-center p-3'>

                                                    {

                                                        CronData.TicketSync.map((item, i) => {
                                                            return (
                                                                <div className='col-sm-6' key={i} >
                                                                    <div className={"card p-3 my-3 custom-indicator-card gap-2 indicator-" + item.IndicatorFlag + "-bg"}>
                                                                        <div className={"custom-indicator indicator-" + item.IndicatorFlag} ></div>
                                                                        <p className='m-0'>{item.DisplayName}</p>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                        : null
                                    }

                                    {CronData.Other !== undefined && CronData.Other.length > 0 ? <div className='col-sm-4'>
                                        <div className="card">
                                            <div className="card-header py-3 justify-content-center align-items-center gap-2 cron-status-card-header" >
                                                {/* <div className="custom-indicator indicator-green" ></div> */}
                                                <h5 className='m-0'>Other Sync</h5>
                                            </div>

                                            <div className='row justify-content-center p-3'>
                                                {
                                                    CronData.Other.map((item, i) => {
                                                        return (
                                                            <div className='col-sm-6' key={i}>
                                                                <div className={"card p-3 my-3 custom-indicator-card gap-2 indicator-" + item.IndicatorFlag + "-bg"}>
                                                                    <div className={"custom-indicator indicator-" + item.IndicatorFlag} ></div>
                                                                    <p className='m-0'>{item.DisplayName}</p>
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                        : null
                                    }
                                </div>
                                : null
                        }

                    </div>
                </div>
            </div>
        </>
    )
}

export default CronStatus