import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../../axios";
import Loader from "../../Components/Loader";
import { toast, ToastContainer } from "react-toastify";
import DefaultLogo from "../../Images/foxilogic-disable.png"
import ReactCountryFlag from "react-country-flag"
import { CUSTOM_CLIENT_SELECT } from "../../Custom";
import Switch from "react-switch";

const AddUser = () => {
  const { id } = useParams();
  const [loader, setLoader] = useState(false);
  const [Name, setName] = useState("");
  const [Surname, setSurname] = useState("");
  const [Username, setUsername] = useState("");
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [ConfirmPassword, setConfirmPassword] = useState("");
  const [UsergroupAll, setUsergroupAll] = useState([]);
  const [Usergroup, setUsergroup] = useState("");
  const [Role, setRole] = useState("");
  const [Roles, setRoles] = useState([]);
  const [Client, setClient] = useState("");

  const [NameError, setNameError] = useState("");
  const [SurnameError, setSurnameError] = useState("");
  const [UsernameError, setUsernameError] = useState("");
  const [EmailError, setEmailError] = useState("");
  const [PasswordError, setPasswordError] = useState("");
  const [ConfirmPasswordError, setConfirmPasswordError] = useState("");
  const [UsergroupError, setUsergroupError] = useState("");
  const [RoleError, setRoleError] = useState("");
  const [ClientError, setClientError] = useState("");
  const [CountryError, setCountryError] = useState("");
  const [ClientList, setClientList] = useState([]);
  const [CountryAll, setCountryAll] = useState([]);
  const [Country, setCountry] = useState("");

  const [ChannelPartnerAll, setChannelPartnerAll] = useState([]);
  const [ChannelPartner, setChannelPartner] = useState("");
  const [ChannelPartnerError, setChannelPartnerError] = useState("");

  const [Logo, setLogo] = useState("")
  const [LogoImage, setLogoImage] = useState("")
  const [LogoError, setLogoError] = useState("");
  const [Activated, setActivated] = useState(false);
  const [ServiceCall, setServiceCall] = useState(true);
  const [CountryWiseCustomSpArr, setCountryWiseCustomSpArr] = useState([]);
  const [CustomSpArr, setCustomSpArr] = useState([]);
  const [CustomSp, setCustomSp] = useState("0");
  const [CustomSpError, setCustomSpError] = useState("");

  const navigate = useNavigate();

  const getClientValue = () => {
    if (Client) {
      for (var i = 0; i < ClientList.length; i++) {
        if (ClientList[i].Id === parseInt(Client)) {
          return [{ value: ClientList[i].Id, label: ClientList[i].Name, image: ClientList[i].image }];
        }
      }
    }
  }

  const handleValidation = () => {
    setNameError("");
    setSurnameError("");
    setUsernameError("");
    setPasswordError("");
    setConfirmPasswordError("");
    setUsergroupError("");
    setRoleError("");
    setClientError("");
    setCountryError("");
    setChannelPartnerError("");
    setLogoError("")
    setEmailError("")
    setCustomSpError("");

    var flag = true;
    if (Name === "") {
      setNameError("Name is required");
      flag = false;
    }
    if (Surname === "") {
      setSurnameError("Surname is required");
      flag = false;
    }
    if (Username === "") {
      setUsernameError("Username is required");
      flag = false;
    }

    if (id === undefined) {

      if (ConfirmPassword !== Password) {
        setConfirmPasswordError(
          "Your password and confirmation password do not match."
        );
        flag = false;
      }

      if (ConfirmPassword !== "" && Password === "") {
        setConfirmPasswordError(
          "Please enter password."
        );
        flag = false;
      }

      if (Email === "") {
        setEmailError("Email is required")
        flag = false;
      } else {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(Email)) {
          setEmailError("Please enter valid email address")
          flag = false;
        }
      }
    }

    if (Usergroup === "") {
      setUsergroupError("Usergroup is required");
      flag = false;
    }

    if (Role === "") {
      setRoleError("Role is required");
      flag = false;
    }

    if (Usergroup === "3" || Usergroup === "4" || Usergroup === "5" || Usergroup === "6" || Usergroup === "7") {
      if (Client === "") {
        setClientError("Client is required");
        flag = false;
      }
    }

    if (Usergroup === "7") {
      if (ChannelPartner === "") {
        setChannelPartnerError("Channel Partner is required")
        flag = false;
      }
    }

    if (Usergroup === "4" || Usergroup === "5" || Usergroup === "6" || Usergroup === "7") {
      if (Country === "") {
        setCountryError("Country is required");
        flag = false;
      }
    }

    if (parseInt(Usergroup) === 5) {
      if (parseInt(CustomSp) === 0) {
        setCustomSpError("Please select custom service provider.")
        flag = false;
      }
    }

    return flag;
  };

  useEffect(() => {
    if (parseInt(Usergroup) === 5 && Country) {
      let IsCountryFound = false;

      for (let i = 0; i < CountryWiseCustomSpArr.length; i++) {
        if (parseInt(Country) === parseInt(CountryWiseCustomSpArr[i].ClientCountryId)) {
          setCustomSpArr(CountryWiseCustomSpArr[i].CustomSp);
          IsCountryFound = true;
          break;
        }
      }

      if (!IsCountryFound) {
        setCustomSpArr([]);
      }
    }
  }, [Usergroup, Country, CountryWiseCustomSpArr]);


  const formSubmit = (e) => {
    e.preventDefault();
    const flag = handleValidation();
    if (flag) {
      const payload = new FormData();
      const TempActivated = Activated ? 1 : 0
      payload.append("Name", Name);
      payload.append("Surname", Surname);
      payload.append("Username", Username);
      payload.append("Password", Password);
      payload.append("ConfirmPassword", ConfirmPassword);
      payload.append("UserGroupId", Usergroup);
      payload.append("RoleId", Role);
      payload.append("ClientId", Client);
      payload.append("ClientCountryId", Country);
      payload.append("ChannelPartner", ChannelPartner);
      payload.append("Email", Email);
      payload.append("CustomSpId", CustomSp);

      if (typeof Logo !== "string") {
        payload.append("ProfileImage", Logo);
      }

      if (id) {
        payload.append("Activated", TempActivated);
        setLoader(true);
        axios
          .post("user/" + id + "?_method=PUT", payload)
          .then((response) => {
            navigate("/users", {
              state: response.data.status.message,
            });
            setLoader(false);
          })
          .catch(() => {
            setLoader(false);
          });
      } else {
        payload.append("Activated", 1);
        setLoader(true);
        axios
          .post("user", payload)
          .then((response) => {
            navigate("/users", {
              state: response.data.status.message,
            });
            setLoader(false);
          })
          .catch((response) => {
            setLoader(false);
            toast.error(response.response.data.status.message);
          });
      }
    }
  };

  useEffect(() => {
    if (id && ServiceCall) {
      setLoader(true);
      axios
        .get("user/" + id)
        .then((response) => {
          setName(response.data.data.Name);
          setSurname(response.data.data.Surname);
          setUsername(response.data.data.Username);
          setEmail(response.data.data.Email);
          setUsergroup(response.data.data.UserGroupId);
          if (typeof response.data.data.ProfileImage !== "undefined") {
            setLogo(response.data.data.ProfileImage)
            setLogoImage(response.data.data.ProfileImage)
          }
          setRole(response.data.data.RoleId);
          setClient(response.data.data.ClientId);
          for (var i = 0; i < ClientList.length; i++) {
            if (ClientList[i].Id === response.data.data.ClientId) {
              setCountryAll(ClientList[i].countries);
              break;
            }
          }
          setCountry(response.data.data.ClientCountryId);
          setChannelPartner(response.data.data.ChannelPartnerId)
          setCustomSp(response.data.data.CustomSpId)
          setActivated(response.data.data.Activated)
          setLoader(false);
          setServiceCall(false)
        })
        .catch(() => {
          setLoader(false);
          setServiceCall(false)
        });
    }
  }, [id, UsergroupAll, ClientList, ServiceCall]);

  useEffect(() => {
    setLoader(true);
    axios
      .get("clients")
      .then((response) => {

        var TempData = response.data.data;
        var arr = [];
        for (var i = 0; i < TempData.length; i++) {
          var obj = TempData[i]
          obj["label"] = TempData[i].Name;
          obj["value"] = TempData[i].Id;
          obj["image"] = TempData[i].ClientLogo ? TempData[i].ClientLogo : DefaultLogo;
          arr.push(obj)
        }
        setClientList(arr)

        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  }, []);

  const OnchangeClient = (selected) => {
    var value = selected.Id;

    for (var i = 0; i < ClientList.length; i++) {
      if (ClientList[i].Id === parseInt(value)) {
        setCountryAll(ClientList[i].countries);
        break;
      }
    }
    setClient(value);
    setCustomSpArr([])
  };

  useEffect(() => {
    setLoader(true);
    axios
      .get("masters")
      .then((response) => {
        setUsergroupAll(response.data.data.UserGroups);
        setRoles(response.data.data.UserGroups[0].roles);
        setChannelPartnerAll(response.data.data.ChannelPartners);
        setRoles(response.data.data.Roles)
        setCountryWiseCustomSpArr(response.data.data.AllCountryWiseCustomSp);
        // setRole(response.data.data.Roles[0].RoleName)
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  }, []);

  const UsergroupHandler = (e) => {
    const value = e.target.value;

    if (parseInt(value) === 3 || parseInt(value) === 2) {
      setCountry('')
    }

    if (parseInt(value) === 2) {
      setClient('')
    }
    setUsergroup(value);
  };

  const onchangeLogo = (event) => {
    setLogo("")
    setLogoError("")
    setLogoImage("")
    var file_name_arr = event.target.files[0].name.split(".");
    var img_height;
    var img_width;

    var _URL = window.URL || window.webkitURL;

    var file, img;
    file = event.target.files[0]
    img = new Image();
    img.onload = function () {
      img_width = this.width;
      img_height = this.height;

      if (img_width !== img_height) {
        setLogoError("Please select square image.")
        setLogo("");
        return false;
      } else {
        if (event.target.files[0].size / 1024 > 500) {
          setLogoError("File size must not be greater than to 500Kb.")
          return
        } else if (!(file_name_arr[file_name_arr.length - 1] === "png" || file_name_arr[file_name_arr.length - 1] === "jpg" || file_name_arr[file_name_arr.length - 1] === "jpeg")) {
          setLogoError("Please select png or jpg image.")
        } else {
          setLogo(event.target.files[0]);
          setLogoImage(URL.createObjectURL(event.target.files[0]))
        }
      }
    };

    img.src = _URL.createObjectURL(file);
  }

  return (
    <>
      <ToastContainer theme="colored" />
      {loader ? <Loader /> : null}
      <div className="row">
        <div className="col-xl-6 mx-auto">
          <div className="card">
            <div className="card-body p-5">
              <div className="card-title">
                <div className="row">
                  <div className="col-md-8">
                    <h5 className="mb-0 text-primary">
                      <i className="bx bxs-user me-1 font-22 text-primary"></i>
                      {id ? " Edit User" : "Add User"}
                    </h5>
                  </div>
                  <div className="col-md-4 text-right">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => {
                        navigate(-1);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
              <hr />
              <form className="row g-3" onSubmit={formSubmit}>
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-12 mt-3">
                      <label htmlFor="inputState" className="form-label">
                        User group
                      </label>
                      <select
                        className="form-select"
                        onChange={UsergroupHandler}
                        value={Usergroup}
                      >
                        <option value="">Select user group</option>
                        {UsergroupAll.map((item) => {
                          return (
                            <option key={item.Id} value={item.Id}>
                              {item.Name}
                            </option>
                          );
                        })}
                      </select>
                      <span className="text-danger">{UsergroupError}</span>
                    </div>
                    <div className="col-md-12 mt-3">
                      <label htmlFor="inputState" className="form-label">
                        Role
                      </label>
                      <select
                        className="form-select"
                        onChange={(e) => setRole(e.target.value)}
                        value={Role}
                      >
                        <option value="">Select role</option>
                        {Roles.map((item) => {
                          return (
                            <option key={item.Id} value={item.Id}>
                              {item.RoleName}
                            </option>
                          );
                        })}
                      </select>
                      <span className="text-danger">{RoleError}</span>
                    </div>
                    {parseInt(Usergroup) === 3 || parseInt(Usergroup) === 4 || parseInt(Usergroup) === 5 || parseInt(Usergroup) === 6 || parseInt(Usergroup) === 7 ? (
                      <div className="col-md-12 mt-3">
                        <label htmlFor="inputState" className="form-label">
                          Clients
                        </label>
                        {CUSTOM_CLIENT_SELECT({ ClientListOptions: ClientList, Value: getClientValue(), OnchangeClient: OnchangeClient })}
                        <span className="text-danger">{ClientError}</span>
                      </div>
                    ) : null}
                    {parseInt(Usergroup) === 4 || parseInt(Usergroup) === 5 || parseInt(Usergroup) === 6 || parseInt(Usergroup) === 7 ? (
                      <div className="col-md-12 mt-3">
                        <label htmlFor="inputState" className="form-label">
                          Country
                        </label>
                        <select
                          className="form-select"
                          onChange={(e) => { setCountry(e.target.value); setCustomSpArr([]) }}
                          value={Country}
                        >
                          <option value="">Select Country</option>
                          {CountryAll.map((item) => {
                            return (
                              <option key={item.Id} value={item.Id}>

                                {item.CountryCode ?
                                  <ReactCountryFlag
                                    className="add_country_flag mx-5"
                                    countryCode={item.CountryCode}
                                    aria-label={item.CountryCode}
                                    style={{
                                    }}
                                  />
                                  : <i
                                    className="bx bx-flag"
                                    title="Manage country"
                                    style={{ fontSize: "20px", cursor: "pointer", marginRight: "10px" }}
                                  ></i>
                                }

                                &nbsp;&nbsp;&nbsp;{item.CountryName}
                              </option>
                            );
                          })}
                        </select>
                        <span className="text-danger">{CountryError}</span>
                      </div>
                    ) : null}

                    {parseInt(Usergroup) === 5 && Country ? (
                      <div className="col-md-12 mt-4">
                        <label htmlFor="inputState" className="form-label">
                          Custom Service Provider
                        </label>
                        <select
                          className="form-select"
                          onChange={(e) => setCustomSp(e.target.value)}
                          value={CustomSp}
                        >
                          <option value="0">Select Custom Service Provider</option>
                          {CustomSpArr.map((item) => {
                            return (
                              <option key={item.Id} value={item.Id}>
                                {item.CustomSp}
                              </option>
                            );
                          })}
                        </select>
                        <span className="text-danger">{CustomSpError}</span>
                      </div>
                    ) : null}


                    {parseInt(Usergroup) === 7 ? (
                      <div className="col-md-12 mt-3">
                        <label htmlFor="inputState" className="form-label">
                          Channel Partner
                        </label>
                        <select
                          className="form-select"
                          onChange={(e) => setChannelPartner(e.target.value)}
                          value={ChannelPartner}
                        >
                          <option value="">Select Channel Partner</option>
                          {ChannelPartnerAll.map((item) => {
                            return (
                              <option key={item.Id} value={item.Id}>
                                {item.Name}
                              </option>
                            );
                          })}
                        </select>
                        <span className="text-danger">{ChannelPartnerError}</span>
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-12 mt-3">
                      <label htmlFor="inputFirstName" className="form-label">
                        Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => setName(e.target.value)}
                        value={Name}
                      />
                      <span className="text-danger">{NameError}</span>
                    </div>
                    <div className="col-md-12 mt-3">
                      <label htmlFor="inputLastName" className="form-label">
                        Surname
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => setSurname(e.target.value)}
                        value={Surname}
                      />
                      <span className="text-danger">{SurnameError}</span>
                    </div>
                    <div className="col-md-12 mt-3">
                      <label htmlFor="inputEmail" className="form-label">
                        Username
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => setUsername(e.target.value)}
                        value={Username}
                      />
                      <span className="text-danger">{UsernameError}</span>
                    </div>
                    <div className="col-md-12 mt-3">
                      <label htmlFor="inputEmail" className="form-label">
                        Email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        onChange={(e) => setEmail(e.target.value)}
                        value={Email}
                      />
                      <span className="text-danger">{EmailError}</span>
                    </div>
                    <div className="col-md-12 mt-3">
                      <label htmlFor="inputPassword" className="form-label">
                        Password
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        onChange={(e) => setPassword(e.target.value)}
                        value={Password}
                      />
                      <span className="text-danger">{PasswordError}</span>
                    </div>
                    <div className="col-md-12 mt-3">
                      <label htmlFor="inputPassword" className="form-label">
                        Confirm password
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        value={ConfirmPassword}
                      />
                      <span className="text-danger">
                        {ConfirmPasswordError}
                      </span>
                    </div>

                    <div className="col-md-12 mt-3">
                      <input
                        type="file"
                        className="form-control"
                        name="user_logo"
                        accept=
                        "image/jpeg, image/png"
                        onChange={(event) => {
                          if (event.target.files && event.target.files[0]) {
                            onchangeLogo(event);
                          }
                        }}
                      />

                      {
                        LogoImage !== "" && typeof LogoImage !== 'undefined' ? <img
                          src={LogoImage}
                          className="mt-3 user-img"
                          alt="User Img"
                          style={{
                            border: "1px solid white",
                            width: "90px",
                            height: "90px",
                          }}
                        /> : ""
                      }

                      <div className="text-danger mt-1" >{LogoError}</div>
                    </div>
                    {id ? <div className="col-md-12 mt-3">
                      <label htmlFor="activated " className="form-label">
                        Activated ( If user is activated then user can access the portal)
                      </label>
                      <Switch
                        className="login-switch m-0 mx-2"
                        onColor={"#136dfa"}
                        height={25}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        onChange={() => {
                          setActivated(!Activated)
                        }}
                        checked={Activated}
                      />&nbsp;

                    </div> : ""}
                  </div>
                </div>

                <div className="col-12">
                  <button type="submit" className="btn btn-primary px-5">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddUser;
