import React, { useEffect, useState, useRef } from 'react'
import Loader from '../../Components/Loader'
import axios from '../../axios';
import { toast } from 'react-toastify';
import { default as ReactSelect } from "react-select";
import { components } from "react-select";

const Option = (props) => {
    return (
        <div className="custom-select-option">
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />
                &nbsp;
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

const ManualMerakiTicketGenaration = (props) => {
    const DivRef = useRef(null);

    const [loader, setLoader] = useState(false);
    const [BasestationMainList, setBasestationMainList] = useState([]);
    const [BasestationList, setBasestationList] = useState([]);
    const [Basestation, setBasestation] = useState("");
    const [BasestationError, setBasestationError] = useState("");

    const [IncidentCodeList, setIncidentCodeList] = useState([]);
    const [IncidentCode, setIncidentCode] = useState("");
    const [IncidentCodeError, setIncidentCodeError] = useState("");

    const [SitesList, setSitesList] = useState([]);
    const [Sites, setSites] = useState("");
    const [SitesError, setSitesError] = useState("");

    useEffect(() => {

    }, [Sites])

    useEffect(() => {
        if (props.CountryID) {
            setLoader(true);
            axios
                .get(
                    "get_manual_ticket_masters?ClientCountryId=" + props.CountryID
                )
                .then((response) => {
                    setBasestationMainList(response.data.data.Basestation)
                    setIncidentCodeList(response.data.data.MerakiIncidentCodes)
                    // setSitesList(response.data.data.Sites)

                    var Temp = response.data.data.Sites;
                    var Arr = [];
                    for (var i = 0; i < Temp.length; i++) {
                        var Obj = Temp[i];
                        Obj['value'] = Temp[i].SiteId;
                        Obj['label'] = Temp[i].Name;
                        Arr.push(Obj);
                    }
                    setSitesList(Arr)
                    setLoader(false);
                })
                .catch((response) => {

                });
        }

    }, [props.CountryID])

    const HandleSubmit = (e) => {
        setIncidentCodeError("");
        setSitesError("");
        setBasestationError("");
        var flag = true;
        if (IncidentCode === "") {
            flag = false;
            setIncidentCodeError("Please select incident code")
        }
        if (Sites === "") {
            flag = false;
            setSitesError("Please select site")
        }
        if (Basestation === "") {
            flag = false;
            setBasestationError("Please select basestation")
        }

        if (flag) {
            setLoader(true);
            var Payload = {
                "ClientCountryId": props.CountryID,
                "BasestationId": Basestation,
                "TestType": IncidentCode,
                "SiteName": Sites.Name
            }

            axios
                .post(
                    "meraki_manual_ticket", Payload
                )
                .then((response) => {
                    toast.success(response.data.status.message)
                    props.setManualTicketFlag(false);
                    props.setServiceCall(true);
                    setIncidentCode("");
                    setSites("");
                    setBasestation("");
                    setLoader(false);
                })
                .catch((response) => {
                    setLoader(false);
                });

        }
    }

    const handleCloseClick = (event) => {
        if (DivRef.current && !DivRef.current.contains(event.target)) {
            props.setManualTicketFlag(false)
        }
    }


    return (
        <>
            {loader ? <Loader /> : null}
            <div
                className='popup-background'
                onClick={(e) => {
                    handleCloseClick(e)
                }}
            >
                <div
                    className="card"
                    style={{
                        maxWidth: "25%",
                        height: "fit-content",
                        margin: "auto",
                        // textAlign: "center",
                        boxShadow: "0px 0px 5px 0px #5c5c5c",
                    }}
                    ref={DivRef}

                >
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-10 text-center mb-3" >
                                <h5>Manual Meraki Ticket Generation</h5>
                            </div>

                            <div className="col-md-2 text-right">
                                <button className="btn btn-secondary"
                                    onClick={() => {
                                        props.setManualTicketFlag(false)
                                    }}
                                >x</button>
                            </div>

                            <div className="col-md-12 mb-4">
                                <div className='row mt-3'>
                                    <div className="col-sm-12">
                                        <select
                                            className="form-select mb-3"
                                            onChange={(e) => {
                                                setIncidentCode(e.target.value)
                                            }}
                                            value={IncidentCode}
                                        >
                                            <option value="">Select Incident Code</option>
                                            {
                                                IncidentCodeList.map((item, index) => (
                                                    <option key={index} value={item.TestType}> {item.IncidentCode} </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <p style={{
                                        color: "red", marginTop: "-10px", marginBottom: "5px"
                                    }}>{IncidentCodeError}</p>

                                    <div className="col-sm-12 text-left">
                                        {/* <select
                                            className="form-select mb-3"
                                            onChange={(e) => {
                                                setSites(e.target.value);
                                                var Arr = []
                                                for (var i = 0; i < BasestationMainList.length; i++) {
                                                    if (BasestationMainList[i].SiteId === e.target.value) {
                                                        Arr.push(BasestationMainList[i]);
                                                    }
                                                }
                                                setBasestationList(Arr);
                                            }}
                                            value={Sites}
                                        >
                                            <option value="">Select Site</option>
                                            {
                                                SitesList.map((item, index) => (
                                                    <option key={index} value={item.SiteId}> {item.Name} </option>
                                                ))
                                            }
                                        </select> */}

                                        <ReactSelect
                                            options={SitesList}
                                            placeholder='Select site'
                                            closeMenuOnSelect={true}
                                            hideSelectedOptions={false}
                                            // defaultMenuIsOpen
                                            components={{
                                                Option
                                            }}
                                            onChange={(selected) => {
                                                setSites(selected);
                                                var Arr = []
                                                for (var i = 0; i < BasestationMainList.length; i++) {
                                                    if (BasestationMainList[i].SiteId === selected.SiteId) {
                                                        Arr.push(BasestationMainList[i]);
                                                    }
                                                }
                                                setBasestationList(Arr);
                                            }}
                                            allowSelectAll={true}
                                            value={Sites ? Sites : null}
                                        />
                                    </div>
                                    <p style={{
                                        color: "red", marginTop: "-8px", marginBottom: "1px"
                                    }}>{SitesError}</p>


                                    <div className="col-sm-12" style={{ marginTop: "6px" }}>
                                        <select
                                            className="form-select mb-3"
                                            onChange={(e) => {
                                                setBasestation(e.target.value)
                                            }}
                                            value={Basestation}
                                        >
                                            <option value="">Select Basestation</option>
                                            {
                                                BasestationList.map((item, index) => (
                                                    <option key={index} value={item.BaseStationId}> {item.BaseStationId} </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <p style={{
                                        color: "red", marginTop: "-10px", marginBottom: "5px"
                                    }}>{BasestationError}</p>

                                </div>
                            </div>
                            <div className='col-md-12 mb-3 row'>
                                <div className='col-md-12' style={{ textAlign: "center" }}>
                                    <button title="Back" className="btn btn-primary" style={{ boxShadow: '0px 0px 5px 0px #4f4f4f', borderRadius: '15px', padding: '5px 20px' }} onClick={(e) => {
                                        HandleSubmit();
                                    }}>
                                        Generate
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ManualMerakiTicketGenaration