import React, { useState, useRef, useEffect } from 'react';

const Curl = (props) => {
    const DivRef = useRef(null);
    const [CurlCommand, setCurlCommand] = useState("");
    const [CurlCommand2, setCurlCommand2] = useState("");
    const [copySuccess, setCopySuccess] = useState("");  // For copy feedback

    const handleCloseClick = (event) => {
        if (DivRef.current && !DivRef.current.contains(event.target)) {
            props.setCurlData({});
        }
    };

    // Function to format the cURL string for better readability
    const formatCurlString = (curl) => {
        const lines = curl.split(/(?=-H|-X|-d)/).map(line => line.trim());
        return lines.map((line, index) => (
            <div key={index}>
                <span style={{ color: 'blue' }}>{line.startsWith('curl') ? 'curl' : line}</span>
            </div>
        ));
    };

    const formatHeaders = (headers) => {
        return Object.entries(headers)
            .map(([key, value]) => `-H "${key}: ${value}"`)
            .join(' ');
    };

    // Function to handle copy to clipboard
    const handleCopy = (command) => {
        navigator.clipboard.writeText(command).then(() => {
            setCopySuccess("Copied!");
            setTimeout(() => setCopySuccess(""), 2000);  // Clear success message after 2 seconds
        }, () => {
            setCopySuccess("Failed to copy.");
        });
    };

    useEffect(() => {
        if (props.CurlData.CreateTicket) {
            // Generate cURL string for CreateTicket
            props.CurlData.CreateTicket.Payload = {
                "BasestationId": "C0BB",
                "BasestationLessorId": "SQD_ZN_G_99",
                "TestType": "COMMUNICATION_STATUS",
                "TicketId": 52371843,
                "TicketStatus": "Open",
                "CreatedTimestamp": 1728623040,
                "IncidentCode": "BS-NO-COMMS",
                "SiteName": "BONGWANA_351775 - SQD_ZN_G_99",
                "SiteHost": "ATC",
                "SiteStatus": 0,
                "TicketCategory": 2,
                "DynamicSla": "Silver",
                "CreatedFrom": "SYSTEM",
                "SiteMaintenanceName": "Cirtech",
                "FreshServiceTicketId": "99999999999999",
                "EmailSubject": "2024/10/11 07:04 SAST - BONGWANA_351775 - SQD_ZN_G_99 | BS-NO-COMMS | Silver"
            };

            const { Method, Url, Headers, Payload } = props.CurlData.CreateTicket;
            const formattedHeaders = formatHeaders(JSON.parse(Headers));

            const curlRequest = `curl -X ${Method} "${Url}" -H "Content-Type: application/json" ${formattedHeaders} -d '${JSON.stringify(Payload)}'`;
            setCurlCommand(curlRequest);
        }

        if (props.CurlData.UpdateTicket && props.CurlData.UpdateTicket.Url && props.CurlData.UpdateTicket.Method) {
            // Generate cURL string for UpdateTicket
            props.CurlData.UpdateTicket.Payload = {
                "TicketId": 122333,
                "TicketStatus": "Deferred",
                "Description": "Ticket status changed to Deferred",
                "ActionTime": 1728985756
            };

            const { Method, Url, Headers, Payload } = props.CurlData.UpdateTicket;

            const formattedHeaders = JSON.stringify(props.CurlData.UpdateTicket.Headers) !== "[]" ? formatHeaders(JSON.parse(Headers)) : "";

            const curlRequest2 = `curl -X ${Method} "${Url}" -H "Content-Type: application/json" ${formattedHeaders} -d '${JSON.stringify(Payload)}'`;
            setCurlCommand2(curlRequest2);
        }
    }, [props.CurlData]);

    return (
        <>
            <div className='popup-background' onClick={handleCloseClick}>
                <div
                    className="card"
                    style={{ height: "fit-content", margin: "auto", boxShadow: "0px 0px 5px 0px #5c5c5c" }}
                    ref={DivRef}
                >
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-11 text-center mb-4">
                                <h4>Curl Request</h4>
                            </div>
                            <div className="col-md-1 text-right mb-4">
                                <button className="btn btn-secondary" onClick={() => props.setCurlData({})}>x</button>
                            </div>
                        </div>
                        <div className='row' style={{ width: "1500px" }}>
                            <p className='text-center'><strong>Note :</strong> The curl request below includes the country key in the headers, and the payload provided is for example purposes only.</p>
                            <div className="col-md-6">
                                <div className='card p-4'>
                                    <div className="card-header">
                                        <h6 className='text-center my-1'>Create Ticket</h6>
                                    </div>
                                    <div className="card-body">
                                        <pre>{formatCurlString(CurlCommand)}</pre>
                                        <i className='bx bx-copy' onClick={() => handleCopy(CurlCommand)}></i>

                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className='card p-4'>
                                    <div className="card-header">
                                        <h6 className='text-center my-1'>Update Ticket</h6>
                                    </div>
                                    <div className="card-body">
                                        <pre>{formatCurlString(CurlCommand2)}</pre>
                                        <i className='bx bx-copy' onClick={() => handleCopy(CurlCommand2)}></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {copySuccess && <p className="text-center">{copySuccess}</p>}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Curl;
