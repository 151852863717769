import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from "react-toastify";
import axios from "../../axios";
import Loader from '../../Components/Loader';
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import Alert from "../../Components/Alert";
import DefaultLogo from "../../Images/foxilogic-disable.png"
import ReactCountryFlag from "react-country-flag"
import { CUSTOM_CLIENT_SELECT } from '../../Custom';

const List = () => {
    var UserGroup = JSON.parse(localStorage.getItem("UserGroup"));

    const [ClientList, setClientList] = useState([]);
    const [ClientId, setClientId] = useState("");
    const [CountryList, setCountryList] = useState([]);
    const [CountryId, setCountryId] = useState("");

    const location = useLocation();
    const navigate = useNavigate();

    const [ModuleData, setModuleData] = useState([])
    const [loader, setLoader] = useState("")
    const [DeleteUserId, setDeleteUserId] = useState("");
    const [ServiceCall, setServiceCall] = useState(false);

    useEffect(() => {
        if (location.state) {
            setClientId(location.state.ClientId)
            setCountryId(location.state.CountryId)
            setServiceCall(true);

        }
    }, [location, ClientId, CountryId]);

    useEffect(() => {
        for (var i = 0; i < ClientList.length; i++) {
            if (ClientList[i].Id === parseInt(ClientId)) {
                setCountryList(ClientList[i].countries)
                break
            }
        }
    }, [ClientId, ClientList]);

    useEffect(() => {
        if (UserGroup.Id === 1 || UserGroup.Id === 2) {
            setLoader(true);
            axios
                .get("clients")
                .then((response) => {
                    var TempData = response.data.data;
                    var arr = [];
                    for (var i = 0; i < TempData.length; i++) {
                        var obj = TempData[i]
                        obj["label"] = TempData[i].Name;
                        obj["value"] = TempData[i].Id;
                        obj["image"] = TempData[i].ClientLogo ? TempData[i].ClientLogo : DefaultLogo;
                        arr.push(obj)
                    }
                    setClientList(arr)
                    setLoader(false);
                })
                .catch(() => {
                    setLoader(false);
                });
        }
    }, [UserGroup.Id]);

    const OnchangeClient = (selected) => {
        setClientId(selected.Id)
        setCountryId("");
    }

    const getClientValue = () => {
        if (ClientId) {
            for (var i = 0; i < ClientList.length; i++) {
                if (parseInt(ClientList[i].Id) === parseInt(ClientId)) {
                    return [{ value: ClientList[i].Id, label: ClientList[i].Name, image: ClientList[i].image }];
                }
            }
        }
    }

    useEffect(() => {
        if (ClientId && CountryId && ServiceCall) {
            setLoader(true)
            axios.get("custom_sp_config?ClientId=" + ClientId + "&ClientCountryId=" + CountryId)
                .then((response) => {
                    setModuleData(response.data.data)
                    setLoader(false)
                    setServiceCall(false)
                })
                .catch((error) => {
                    setLoader(false)
                })
        }

    }, [ClientId, CountryId, ServiceCall])

    const AlertResponse = (response) => {
        if (response === true) {
            setLoader(true);
            axios
                .delete("custom_sp_config/" + DeleteUserId)
                .then((response) => {
                    setServiceCall(true);
                    setDeleteUserId("");
                    toast.success("Record deleted successfully");
                    setLoader(false);
                })
                .catch(() => {
                    setLoader(false);
                });
        } else {
            setDeleteUserId("");
        }
    };

    return (
        <>
            <ToastContainer theme="colored" />
            {loader ? <Loader /> : null}

            {DeleteUserId ?
                <Alert
                    message="Are you sure, you want to delete this record?"
                    AlertResponse={AlertResponse}
                />
                :
                ""
            }

            {/* {loader ? <Loader height={LoaderHeight} /> : null} */}
            <div className="row">
                <div className="col-md-8">
                    <h6 className="mb-0"> Service Provider Configuration</h6>
                </div>

            </div>
            <hr />
            <div className="row">
                <div className="col-md-2">
                    {UserGroup.Id === 1 || UserGroup.Id === 2 ? (
                        <>
                            {CUSTOM_CLIENT_SELECT({ ClientListOptions: ClientList, Value: getClientValue(), OnchangeClient: OnchangeClient })}
                        </>

                    ) : null}
                </div>
                <div className="col-md-2">
                    {ClientId ? <select
                        className="form-select mb-3"
                        onChange={(e) => {
                            setCountryId(e.target.value)
                            setServiceCall(true);
                        }}

                        value={CountryId}
                    >
                        <option value="">Select Country</option>
                        {
                            CountryList.map((item, index) => (
                                <option key={index} value={item.Id}>
                                    {item.CountryCode ?
                                        <ReactCountryFlag
                                            className="add_country_flag mx-5"
                                            countryCode={item.CountryCode}
                                            aria-label={item.CountryCode}
                                            style={{
                                            }}
                                        />
                                        : <i
                                            className="bx bx-flag mx-1 p-0"
                                            title="Country"
                                            style={{ fontSize: "21px", cursor: "pointer" }}
                                        ></i>
                                    }
                                    &nbsp;&nbsp;&nbsp;{item.CountryName}
                                </option>
                            ))
                        }
                    </select> : null}
                </div>
                <div className="col-md-8">
                    {ClientId && CountryId ?
                        <button
                            type="submit"
                            className="btn btn-primary px-5"
                            style={{ float: "right", marginLeft: "10px" }}
                            onClick={() => {
                                navigate("/add_custom_sp_config", {
                                    state: {
                                        ClientId: ClientId,
                                        CountryId: CountryId
                                    }
                                })
                            }}
                        >
                            Add
                        </button>
                        : null}
                </div>
            </div>

            {
                ClientId && CountryId ?
                    <div className="card" >
                        <div className="card-body horizontal-scroll">

                            <div className="row">
                                <div className="col-md-12">

                                    <table className="table mb-0 table-striped table-striped ticket-table table-hover">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Service Provider</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>    
                                        <tbody>
                                            {ModuleData.length > 0 ?
                                                ModuleData.map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{item.CustomSp}</td>
                                                            <td className="action-buttons">
                                                                {/* <i
                                                                    className="bx bx-trash text-danger"
                                                                    title="Delete"
                                                                    onClick={() => setDeleteUserId(item.Id)}
                                                                ></i> */}

                                                                <i className="bx bx-pencil box-icon-style" onClick={() => {
                                                                    navigate("/edit_custom_sp_config/" + item.Id, {
                                                                        state: {
                                                                            ClientId: ClientId,
                                                                            CountryId: CountryId
                                                                        }
                                                                    })
                                                                }} title="Edit"></i>

                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                                :
                                                <tr>
                                                    <td
                                                        colSpan={7}
                                                        style={{ textAlign: "center" }}>
                                                        <b>No Alerts Found</b>
                                                    </td>
                                                </tr>}
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                    : null
            }
        </>
    )
}

export default List