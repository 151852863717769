
import React, { useState, useEffect, useRef } from 'react'
import axios from "../../axios";
import Loader from "../../Components/Loader";

const UserToAssignTickets = (props) => {

    const DivRef = useRef(null);
    const [loader, setLoader] = useState(false);
    const [AssignUsers, setAssignUsers] = useState([]);
    // const [AssignedUserId, setAssignedUserId] = useState(0);
    // const [userAssignIndex, setClientCountryId] = useState("");
    // const [userTicketId, setTicketId] = useState("");

    const onChange = (e) => {
        props.setAssignedUserId(e.target.value)
    }

    const handleClose = () => {
        props.setUserAssignIndex("");
        props.setTicketId("");
        props.setAssignedUserId("");
        // props.setClientId("");
        // props.setClientCountryId("");
    }

    useEffect(() => {
        setLoader(true);
        axios
            .get("get_users_to_assign_ticket?ClientId=" + props.ClientId + "&ClientCountryId=" + props.ClientCountryId)
            .then((response) => {
                //console.warn(response);
                setAssignUsers(response.data.data);
                setLoader(false);
            })
            .catch(() => {
                setLoader(false);
            });

    }, [props.ClientId, props.ClientCountryId]);


    const handleCloseClick = (event) => {
        if (DivRef.current && !DivRef.current.contains(event.target)) {
            handleClose();
        }
    }

    return (
        <>
            {loader ? <Loader /> : null}
            <div
                className='popup-background'
                onClick={(e) => {
                    handleCloseClick(e)
                }}
            >
                <div
                    className="card"
                    style={{
                        maxWidth: "30%",
                        height: "fit-content",
                        margin: "auto",
                        textAlign: "center",
                        boxShadow: "0px 0px 5px 0px #5c5c5c",
                    }}
                    ref={DivRef}

                >

                    <div className="card-body horizontal-scroll">
                        <div className="row">
                            <div className="col-md-10 text-left mb-3">
                                {/* <h5>Edit {props.ModuleData[props.recommentIndex].IncidentCode}
                                &nbsp;{props.incidentType} Recommended Fix Config</h5> */}
                                <h5>User To Assign Tickets</h5>
                            </div>

                            <div className="col-md-2 text-right">
                                <button className="btn btn-secondary"
                                    onClick={() => handleClose()}>x</button>
                            </div>

                            <div className="col-md-12 mb-3">
                                <br />
                                <input type="hidden" value={props.userAssignIndex} />
                                <input type="hidden" value={props.TicketId} />

                                <select className="form-select" value={props.AssignedUserId} onChange={onChange} >

                                    <option value="0">--- Select User ---</option>
                                    {AssignUsers.map((item) => {
                                        return (
                                            <option key={item.Id} value={item.Id}>
                                                {item.Name} {item.Surname}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                            <div className='col-md-12 mb-3'>
                                <span className="text-danger" style={{ float: "left" }}>{props.EditTestError}</span>
                            </div>
                            {
                                props.TicketsModuleAccess === 2 ? <div className='col-sm-12'>
                                    <button
                                        type="submit"
                                        className="btn btn-primary px-5"
                                        style={{ float: "right", marginLeft: "10px" }}
                                        onClick={() => props.updateAssignUserId("Save")}
                                    >
                                        Save
                                    </button>
                                </div> : null
                            }
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default UserToAssignTickets