import React, { useEffect, useState } from "react";
import axios from "../../axios";
import Loader from "../../Components/Loader";
import Switch from "react-switch";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Alert from "../../Components/Alert";
import DefaultLogo from "../../Images/foxilogic-disable.png"
import { CUSTOM_CLIENT_SELECT } from '../../Custom';

const List = () => {
  var UserGroup = JSON.parse(localStorage.getItem("UserGroup"));
  var ClientId = JSON.parse(localStorage.getItem("ClientId"));
  const [loader, setLoader] = useState(false);
  const [Clients, setClients] = useState([]);
  const [Client, setClient] = useState(ClientId || "-1");
  const [ModuleData, setModuleData] = useState([]);
  const [WaitingPeriod, setWaitingPeriod] = useState("");
  const [ApplyAllAlert, setApplyAllAlert] = useState("");
  const [success, setSuccess] = useState("");

  const getClientValue = () => {
    if (Client) {
      for (var i = 0; i < Clients.length; i++) {
        if (Clients[i].Id === parseInt(Client)) {
          return [{ value: Clients[i].Id, label: Clients[i].Name, image: Clients[i].image }];
        }
      }
    }
  }

  useEffect(() => {
    if (success) {
      toast.success(success);
      setSuccess("");
    }
    return () => { };
  }, [success]);

  useEffect(() => {
    if (UserGroup.Id === 1 || UserGroup.Id === 2) {
      setLoader(true);
      axios
        .get("clients")
        .then((response) => {

          var TempData = response.data.data;
          var arr = [];
          for (var i = 0; i < TempData.length; i++) {
            if (i === 0 && (UserGroup.Id === 1 || UserGroup.Id === 2)) {
              var default_obj = {};
              default_obj["Id"] = -1;
              default_obj["Name"] = "Default Setting";
              default_obj["label"] = "Default Setting";
              default_obj["value"] = -1;
              default_obj["image"] = DefaultLogo;
              arr.push(default_obj)
            }

            var obj = TempData[i]
            obj["label"] = TempData[i].Name;
            obj["value"] = TempData[i].Id;
            obj["image"] = TempData[i].ClientLogo ? TempData[i].ClientLogo : DefaultLogo;
            arr.push(obj)
          }
          setClients(arr)
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
        });
    }
  }, [UserGroup.Id]);

  useEffect(() => {
    if (Client) {
      setLoader(true);
      axios
        .get("sigfox_auto_fault_waiting?ClientId=" + Client)
        .then((response) => {
          setModuleData(response.data.data);
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
        });
    } else {
      setModuleData([]);
    }
  }, [Client]);

  const handleActive = (index, value) => {
    var ModuleDataTemp = JSON.parse(JSON.stringify(ModuleData));
    ModuleDataTemp[index].Active = value === 0 ? 1 : 0;
    setModuleData(ModuleDataTemp);
  };

  const handleChange = (getIndex, value) => {
    var ModuleDataTemp = JSON.parse(JSON.stringify(ModuleData));
    ModuleDataTemp[getIndex].WaitingPeriod = value;
    setModuleData(ModuleDataTemp)
  }

  const handleValidation = () => {
    var flag = true;
    for (var i = 0; i < ModuleData.length; i++) {
      if (parseInt(ModuleData[i].WaitingPeriod) === 0) {
        flag = false;
      }
    }

    if (!flag) {
      setWaitingPeriod("Please add waiting period for all incident code");
    }
    console.log(flag);

    return flag;
  };

  const saveData = (type) => {
    setWaitingPeriod("");
    const flag = handleValidation();
    if (flag) {
      var data = [];
      for (var i = 0; i < ModuleData.length; i++) {
        data.push({
          TestTypeId: ModuleData[i].TestTypeId,
          WaitingPeriod: ModuleData[i].WaitingPeriod,
          Active: ModuleData[i].Active
        });
      }
      const payload = {
        ClientId: Client,
        CreateFaultConfig: data,
        SubmitType: type
      };
      setLoader(true);
      axios
        .put("sigfox_auto_fault_waiting", payload)
        .then((response) => {
          setModuleData(response.data.data);
          setSuccess(response.data.status.message);
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
        });
    }
  };
  const AlertResponse = (Result) => {
    if (Result) {
      saveData("SaveToAll")
    }
    setApplyAllAlert('')
  }
  const OnchangeClient = (selected) => {
    setClient(selected.Id)
  }

  return (
    <>
      {ApplyAllAlert ? <Alert message={ApplyAllAlert} AlertResponse={AlertResponse} /> : null}
      <ToastContainer theme="colored" />

      {loader ? <Loader /> : null}
      <div className="row">
        <div className="col-md-8">
          <h6 className="mb-0">Sigfox Auto Create Fault Config </h6>
        </div>
      </div>
      <hr />

      <div className="card">
        <div className="card-body horizontal-scroll">
          <div className="row">
            <div className="col-md-6">
              {UserGroup.Id === 1 || UserGroup.Id === 2 ? (
                <>
                  {CUSTOM_CLIENT_SELECT({ ClientListOptions: Clients, Value: getClientValue(), OnchangeClient: OnchangeClient })}
                </>
              ) : null}
            </div>

            {Client ? (
              <div className="col-md-6">
                <button
                  type="submit"
                  className="btn btn-primary px-5"
                  style={{ marginTop: "20px", float: "right", marginLeft: "10px" }}
                  onClick={() => saveData("Save")}
                >
                  Save
                </button>

                {
                  ((UserGroup.Id === 1 || UserGroup.Id === 2) && Client.toString() === "-1") ? <button
                    type="submit"
                    className="btn btn-primary px-5"
                    style={{ marginTop: "20px", float: "right" }}
                    onClick={() => setApplyAllAlert('Are you sure you want to apply all?')}
                  >
                    Apply to all
                  </button> : null
                }

              </div>
            ) : null}
          </div>

          <div className="row">
            <div className="col-md-12">

              <table
                className="table mb-0 table-striped text-center"
                style={{ verticalAlign: "middle" }}
              >
                <thead>
                  <tr>
                    <th>#</th>
                    <th style={{ textAlign: "left" }}>Incident Code</th>
                    <th style={{ textAlign: "left", whiteSpace: "nowrap" }}>Waiting Period</th>
                    <th>Active</th>
                  </tr>
                </thead>
                <tbody>
                  {ModuleData.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td style={{ textAlign: "left" }}>
                          {item.IncidentCode}
                        </td>
                        <td style={{ textAlign: "left", whiteSpace: "nowrap" }}>
                          <input
                            type="number"
                            name="WaitingPeriod"
                            className='form-control'
                            style={{ width: "40%", display: "inline-block" }}
                            value={item.WaitingPeriod}
                            onChange={(e) => handleChange(index, e.target.value)}
                          />
                          &nbsp;&nbsp;
                          <span>hours</span>
                        </td>
                        <td>
                          <div style={{ display: "flex" }}>
                            <Switch
                              disabled={UserGroup.Id !== 1 ? true : false}
                              className="login-switch"
                              onColor={"#136dfa"}
                              height={25}
                              uncheckedIcon={false}
                              checkedIcon={false}
                              onChange={(e) =>
                                handleActive(index, item.Active)
                              }
                              checked={parseInt(item.Active) === 0 ? false : true}
                            />
                          </div>
                        </td>

                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <span className="text-danger">{WaitingPeriod}</span>
            </div>
          </div>

          {Client ? (
            <div className="row">
              <div className="col-md-12">
                <button
                  type="submit"
                  className="btn btn-primary px-5"
                  style={{ marginTop: "20px", float: "right", marginLeft: "10px" }}
                  onClick={() => saveData("Save")}
                >
                  Save
                </button>

                {
                  ((UserGroup.Id === 1 || UserGroup.Id === 2) && Client === "-1") ?
                    <button
                      type="submit"
                      className="btn btn-primary px-5"
                      style={{ marginTop: "20px", float: "right" }}
                      onClick={() => saveData("SaveToAll")}
                    >
                      Apply to all
                    </button> : null
                }

              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default List;
