import React, { useEffect, useRef, useState } from 'react';
import axios from '../../axios';
import { toast } from 'react-toastify';
import Loader from '../../Components/Loader';

const AddClientCallbackSettings = (props) => {
    const DivRef = useRef(null);
    const [loader, setLoader] = useState(false);
    const [CustomSp, setCustomSp] = useState("");
    const [CustomSpError, setCustomSpError] = useState("");
    const [CustomSpArr, setCustomSpArr] = useState([]);

    const [CreateTicket, setCreateTicket] = useState({ Url: "", Method: "", Headers: "{}" });
    const [UpdateTicket, setUpdateTicket] = useState({ Url: "", Method: "", Headers: "{}" });

    const [CreateTicketError, setCreateTicketError] = useState({ Url: "", Method: "", Headers: "" });
    const [UpdateTicketError, setUpdateTicketError] = useState({ Url: "", Method: "", Headers: "" });

    const HandleClose = () => {
        props.setAddFlag(false);
    };

    const ClosePopup = (event) => {
        if (DivRef.current && !DivRef.current.contains(event.target)) {
            HandleClose();
        }
    };

    useEffect(() => {
        if (props.ClientId) {
            axios.get(`client_custom_sp?ClientId=${props.ClientId}`)
                .then((response) => {
                    setCustomSpArr(response.data.data.CustomSpData);
                }).catch((error) => {

                })
        }
    }, [props.ClientId])

    // Validation function to check if headers are valid JSON
    const isValidJSON = (json) => {
        try {
            JSON.parse(json);
            return true;
        } catch (e) {
            return false;
        }
    };

    const HandleSubmit = (e) => {
        e.preventDefault();
        setCustomSpError("");

        // Reset all error messages
        setCreateTicketError({ Url: "", Method: "", Headers: "" });
        // setFaultUpdateError({ Url: "", Method: "", Headers: "" });
        setUpdateTicketError({ Url: "", Method: "", Headers: "" });

        let isValid = true;

        // Validation for Create Ticket fields
        if(CustomSp === ""){
            setCustomSpError("Please select service provider.");
            isValid = false;
        }

        if (CreateTicket.Url === "") {
            setCreateTicketError(prevState => ({ ...prevState, Url: "Please enter your create Sigfox ticket callback URL." }));
            isValid = false;
        }
        if (CreateTicket.Method === "") {
            setCreateTicketError(prevState => ({ ...prevState, Method: "Please enter the method for the create Sigfox ticket." }));
            isValid = false;
        }
        if (CreateTicket.Headers === "" || !isValidJSON(CreateTicket.Headers)) {
            setCreateTicketError(prevState => ({ ...prevState, Headers: "Please enter valid JSON headers." }));
            isValid = false;
        }

        // Validation for Change Ticket Status fields
        if (UpdateTicket.Url === "") {
            UpdateTicket.Url = ""
        }
        if (UpdateTicket.Method === "") {
            UpdateTicket.Method = ""
        }
        if (UpdateTicket.Headers === "" || !isValidJSON(UpdateTicket.Headers)) {
            UpdateTicket.Headers = {}
        }

        // Validation for Change Ticket Status fields
        // if (UpdateTicket.Url === "") {
        //     setUpdateTicketError(prevState => ({ ...prevState, Url: "Please enter your change ticket status callback URL." }));
        //     isValid = false;
        // }
        // if (UpdateTicket.Method === "") {
        //     setUpdateTicketError(prevState => ({ ...prevState, Method: "Please enter the method for changing ticket status." }));
        //     isValid = false;
        // }
        // if (UpdateTicket.Headers === "" || !isValidJSON(UpdateTicket.Headers)) {
        //     setUpdateTicketError(prevState => ({ ...prevState, Headers: "Please enter valid JSON headers." }));
        //     isValid = false;
        // }

        if (isValid && props.ClientId !== "") {
            setLoader(true);
            axios
                .post("add_client_callbacks", {
                    "ClientId": props.ClientId,
                    CreateTicket,
                    UpdateTicket,
                    "CustomSpId":CustomSp

                })
                .then((response) => {
                    toast.success(response.data.status.message);
                    props.setAddFlag(false);
                    props.setServiceCall(true)
                    setLoader(false);
                })
                .catch((error) => {
                    setLoader(false);
                });

        }
    };

    return (
        <>
            {loader ? <Loader /> : null}
            <div
                className='popup-background'
                style={{ zIndex: "99999999", height: "107vh" }}
                onClick={(e) => {
                    ClosePopup(e);
                }}
            >
                <div
                    className="card"
                    style={{
                        width: "70%",
                        height: "fit-content",
                        margin: "auto",
                        boxShadow: "0px 0px 5px 0px #5c5c5c",
                        maxHeight: '90vh'
                    }}
                    ref={DivRef}
                >
                    <div className="card-body horizontal-scroll mt-4">
                        <i
                            className="bx bx-x"
                            title="Close"
                            style={{
                                position: 'absolute',
                                right: '20px',
                                top: '5px',
                                cursor: 'pointer',
                                fontSize: '23px',
                                fontWeight: 'bold'
                            }}
                            onClick={() => HandleClose()}
                        ></i>
                        <div className='row pb-2 mb-3' style={{ borderBottom: "1px solid #bcbbcc" }}>
                            <div className='col-sm-4'>

                            </div>
                            <div className='col-sm-4'>
                                <h4 className="" style={{ textAlign: "center" }}>
                                    <i className="bx bx-cog mx-2" style={{ fontSize: "29px" }}></i>Callback Setting
                                </h4>
                            </div>
                            <div className='col-sm-4 d-flex justify-content-end align-items-center'>
                            </div>
                        </div>

                        <form onSubmit={HandleSubmit}>
                            <div className="row">
                                <div className='col-sm-12 row mb-2'
                                    style={{
                                        display: "flex",
                                        justifyContent: "center"
                                    }}>
                                    <div className='col-sm-4 text-center'>
                                        <label>Service Provider</label>
                                        <select
                                            className="form-select m-2"
                                            onChange={(e) => { setCustomSp(e.target.value) }}
                                            value={CustomSp}
                                        >
                                            <option value="">Select Service Provider</option>
                                            {CustomSpArr.map((item) => {
                                                return (
                                                    <option key={item.CustomSp} value={item.Id} name={item.CustomSp} >
                                                        {item.CustomSp}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                        <p className='text-danger'>{CustomSpError}</p>

                                    </div>


                                </div>

                                {/* Block for Create Ticket URL */}
                                <div className="col-sm-6">
                                    <div className="card p-3" style={{ border: "1px solid #e9e9e9" }}>
                                        <h5 style={{ textAlign: "center" }}>Create Sigfox Ticket Callback</h5>
                                        <label className="form-label">Callback URL</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={CreateTicket.Url}
                                            onChange={(e) => setCreateTicket(prevState => ({ ...prevState, Url: e.target.value }))}
                                        />
                                        <p className="text-danger mt-1">{CreateTicketError.Url}</p>

                                        <label className="form-label">Method</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={CreateTicket.Method}
                                            onChange={(e) => setCreateTicket(prevState => ({ ...prevState, Method: e.target.value }))}
                                        />
                                        <p className="text-danger mt-1">{CreateTicketError.Method}</p>

                                        <label className="form-label">Headers (JSON format)</label>
                                        <textarea
                                            className="form-control"
                                            value={CreateTicket.Headers}
                                            onChange={(e) => setCreateTicket(prevState => ({ ...prevState, Headers: e.target.value }))}
                                        />
                                        <p className="text-danger mt-1">{CreateTicketError.Headers}</p>
                                    </div>
                                </div>

                                {/* Block for Change Ticket Status URL */}
                                <div className="col-sm-6 ">
                                    <div className="card p-3" style={{ border: "1px solid #e9e9e9" }}>
                                        <h5 style={{ textAlign: "center" }}>Change Ticket Status Callback</h5>
                                        <label className="form-label">Callback URL</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={UpdateTicket.Url}
                                            onChange={(e) => setUpdateTicket(prevState => ({ ...prevState, Url: e.target.value }))}
                                        />
                                        <p className="text-danger mt-1">{UpdateTicketError.Url}</p>

                                        <label className="form-label">Method</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={UpdateTicket.Method}
                                            onChange={(e) => setUpdateTicket(prevState => ({ ...prevState, Method: e.target.value }))}
                                        />
                                        <p className="text-danger mt-1">{UpdateTicketError.Method}</p>

                                        <label className="form-label">Headers (JSON format)</label>
                                        <textarea
                                            className="form-control"
                                            value={UpdateTicket.Headers}
                                            onChange={(e) => setUpdateTicket(prevState => ({ ...prevState, Headers: e.target.value }))}
                                        />
                                        <p className="text-danger mt-1">{UpdateTicketError.Headers}</p>
                                    </div>
                                </div>

                                <div className="d-grid mt-4 " style={{ justifyContent: "center" }}>

                                    <button className="btn btn-primary" type="submit">Save</button>
                                </div>
                            </div>

                        </form>
                    </div>
                </div >
            </div >
        </>
    );
};

export default AddClientCallbackSettings;
