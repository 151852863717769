import axios from "../../axios";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import Loader from "../../Components/Loader";
import { toast, ToastContainer } from "react-toastify";

const AddEditCustomSp = () => {

    const { Id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const [ClientId, setClientId] = useState("");
    const [CountryId, setCountryId] = useState("");

    const [loader, setLoader] = useState(false);
    const [CustomSp, setCustomSp] = useState("");
    const [CustomSpError, setCustomSpError] = useState("");

    useEffect(() => {
        if (location.state) {
            setClientId(location.state.ClientId)
            setCountryId(location.state.CountryId)
        }
    }, [location])

    useEffect(() => {
        if (Id && ClientId && CountryId) {
            setLoader(true);
            axios
                .get("custom_sp_config/" + Id + "?ClientId=" + ClientId + "&ClientCountryId=" + CountryId)
                .then((response) => {
                    setCustomSp(response.data.data[0].CustomSp);
                    setLoader(false);
                })
                .catch((error) => {
                    setLoader(false);
                });
        }
    }, [Id, ClientId, CountryId]);

    const handleValidation = () => {
        setCustomSpError("");
        let IsValid = true;

        if (!CustomSp) {
            setCustomSpError("Service Provider field is required.");
            IsValid = false;
        }

        return IsValid;
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const IsValid = handleValidation();

        if (IsValid && ClientId && CountryId) {
            const payload = {
                ClientId: ClientId,
                ClientCountryId: CountryId,
                CustomSp: CustomSp,
            };

            setLoader(true);
            if (!Id) {
                axios
                    .post("add_custom_sp_config", payload)
                    .then((response) => {
                        // navigate("/custom_sp_config?ClientId=" + ClientId + "&CountryId=" + CountryId, {
                        //     state: response.data.status.message,
                        // });
                        toast.success(response.data.status.message);
                        navigate("/custom_sp_config", {
                            state: {
                                ClientId: ClientId,
                                CountryId: CountryId
                            }
                        })
                        setLoader(false);
                    })
                    .catch(() => {
                        setLoader(false);
                    });
            } else {
                axios
                    .put("custom_sp_config/" + Id, payload)
                    .then((response) => {
                        toast.success(response.data.status.message);
                        navigate("/custom_sp_config", {
                            state: {
                                ClientId: ClientId,
                                CountryId: CountryId
                            }
                        })
                        setLoader(false);
                    })
                    .catch(() => {
                        setLoader(false);
                    });
            }
        }
    };

    return (
        <>
            <ToastContainer theme="colored" />
            {loader ? <Loader /> : null}
            <div className="row">
                <div className="col-xl-10 mx-auto">
                    <div className="card">
                        <div className="card-body p-5">
                            <div className="card-title">
                                <div className="row">
                                    <div className="col-md-8">
                                        <h5 className="mb-0 text-primary">
                                            {Id ? "Edit Service Provider" : "Add Service Provider"}
                                        </h5>
                                    </div>
                                    <div className="col-md-4 text-right">
                                        <button
                                            type="button"
                                            className="btn btn-secondary"
                                            onClick={() => {
                                                navigate("/custom_sp_config", {
                                                    state: {
                                                        ClientId: ClientId,
                                                        CountryId: CountryId
                                                    }
                                                })
                                            }}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <form className="row g-3" onSubmit={onSubmit}>
                                <div className="col-md-6">
                                    <label className="form-label">Service Provider</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter CustomSp"
                                        value={CustomSp}
                                        onChange={(e) => {
                                            setCustomSp(e.target.value);
                                            setCustomSpError("");
                                        }}
                                    />
                                    <span className="text-danger">{CustomSpError}</span>
                                </div>
                                <div className="col-12">
                                    <button type="submit" className="btn btn-primary px-5">
                                        Save
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddEditCustomSp;
