import React, { useState, useEffect, useRef } from 'react'
import axios from "../../axios";
import Loader from "../../Components/Loader";
import { toast } from 'react-toastify';
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import { TICKET_STATUS_CLOSED } from '../../Custom';

const Option = (props) => {
    return (
        <div className="custom-select-option">
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />
                &nbsp;
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};


const ResolveDetails = (props) => {
    const [loader, setLoader] = useState(false);
    const [ModuleData, setModuleData] = useState([]);
    const DivRef = useRef(null);
    const UserGroup = JSON.parse(localStorage.getItem("UserGroup"));

    const [ResolutionCodeArr, setResolutionCodeArr] = useState([]);
    const [ResolutionCodes, setResolutionCodes] = useState([]);
    const [FaultCodeArr, setFaultCodesArr] = useState([]);
    const [FaultCodes, setFaultCodes] = useState([]);
    const [ResolutionCodeError, setResolutionCodeError] = useState("");
    const [FaultCodeError, setFaultCodeError] = useState("");

    const [UpdateCount, setUpdateCount] = useState(0);

    const FetchRecord = () => {
        axios
            .get(
                "resolve_details/" + props.ResolvedDetailTicketId.TicketId + "?ClientCountryId=" + props.CountryID
            )
            .then((response) => {
                setModuleData(response.data.data);
                if (response.data.data.FaultCode.length > 0 || response.data.data.ResolutionCode.length > 0) {
                    setUpdateCount(1);
                }
                setLoader(false);
            })
            .catch(() => {
                setLoader(false);
            });
    }

    useEffect(() => {
        if (props.ResolvedDetailTicketId.TicketId) {
            setLoader(true);
            FetchRecord();
        }

    }, [props.ResolvedDetailTicketId.TicketId, props.CountryID])

    useEffect(() => {
        if (props.ResolutionCodesArr) {
            var TempResolutionCodesArr = []
            for (let index = 0; index < props.ResolutionCodesArr.length; index++) {
                props.ResolutionCodesArr[index]["value"] = props.ResolutionCodesArr[index].Id
                props.ResolutionCodesArr[index]["label"] = props.ResolutionCodesArr[index].ResolutionCode
                TempResolutionCodesArr.push(props.ResolutionCodesArr[index]);
            }
            setResolutionCodeArr(TempResolutionCodesArr);
        }

        if (props.FaultCodesArr) {
            var TempFaultCodesArr = []
            for (let index = 0; index < props.FaultCodesArr.length; index++) {
                props.FaultCodesArr[index]["value"] = props.FaultCodesArr[index].Id
                props.FaultCodesArr[index]["label"] = props.FaultCodesArr[index].FaultCode
                TempFaultCodesArr.push(props.FaultCodesArr[index]);
            }
            setFaultCodesArr(TempFaultCodesArr);
        }

    }, [props.ResolutionCodesArr, props.FaultCodesArr])

    const HandleSubmit = () => {
        setResolutionCodeError("");
        setFaultCodeError("");

        let isValid = true;

        // Validate Resolution Codes
        if (ResolutionCodes.length === 0 && FaultCodes.length === 0) {
            setFaultCodeError("Please select at least one resolution code or fault code.");
            isValid = false;
        }

        if (isValid && props.ResolvedDetailTicketId.TicketId && props.CountryID) {
            var TempResolutionCodes = ResolutionCodes.map(function (a) { return a.Id; });
            var TempFaultCodes = FaultCodes.map(function (a) { return a.Id; });

            setLoader(true);
            const Payload = {
                ResolutionCodeIds: TempResolutionCodes,
                FaultCodeIds: TempFaultCodes,
                TicketId: props.ResolvedDetailTicketId.TicketId,
                ClientCountryId: props.CountryID
            };


            axios.post("add_resolve_details", Payload)
                .then((response) => {
                    toast.success(response.data.status.message)
                    setResolutionCodes([])
                    setFaultCodes([])
                    setUpdateCount(UpdateCount + 1)
                    FetchRecord();
                    setLoader(false);
                })
                .catch((errr) => {
                    setLoader(false);
                })
        }
    };

    const handleCloseClick = (event) => {
        if (DivRef.current && !DivRef.current.contains(event.target)) {
            props.setResolvedDetailTicketId({})
        }
    }

    return (
        <>
            {loader ? <Loader /> : null}
            <div
                className='popup-background'
                onClick={(e) => {
                    handleCloseClick(e)
                }}
            >
                <div
                    className="card"
                    style={{
                        width: "40%",
                        height: "fit-content",
                        margin: "auto",
                        boxShadow: "0px 0px 5px 0px #5c5c5c",
                    }}
                    ref={DivRef}
                >
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-11 mb-3" style={{ textAlign: "center" }}>
                                <h5>Resolved Details</h5>
                            </div>

                            <div className="col-md-1 text-right">
                                <button className="btn btn-secondary"
                                    onClick={() => {
                                        props.setResolvedDetailTicketId({})
                                    }}
                                >x</button>
                            </div>
                        </div>

                        {(parseInt(UserGroup.Id) === 1 || parseInt(UserGroup.Id) === 2 ||
                            (parseInt(UserGroup.Id) === 5 && UpdateCount === 0 && props.ResolvedDetailTicketId?.TicketStatus !== TICKET_STATUS_CLOSED)) ?
                            <div className="row block mx-3 my-3 resolve-details-select-option">
                                {/* Resolution Codes Dropdown */}
                                <div className="col-md-12 mb-3 custom-resolvedetails">
                                    <label>Resolution Codes</label>
                                    <ReactSelect
                                        options={ResolutionCodeArr}
                                        placeholder='Select Resolution Code'
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        // defaultMenuIsOpen
                                        components={{
                                            Option
                                        }}
                                        isMulti
                                        onChange={(selected) => {
                                            setResolutionCodes(selected)
                                        }}
                                        allowSelectAll={true}
                                        value={ResolutionCodes ? ResolutionCodes : null}
                                    />

                                    {ResolutionCodeError && (
                                        <p style={{ color: "red" }}>{ResolutionCodeError}</p>
                                    )}
                                </div>

                                {/* Fault Codes Dropdown */}
                                <div className="col-md-12 mb-3">
                                    <label >Fault Codes</label>
                                    <ReactSelect
                                        options={FaultCodeArr}
                                        placeholder='Select Fault Code'
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        components={{
                                            Option
                                        }}
                                        isMulti
                                        onChange={(selected) => {
                                            setFaultCodes(selected)
                                        }}
                                        allowSelectAll={true}
                                        value={FaultCodes ? FaultCodes : null}
                                    />

                                    {FaultCodeError && (
                                        <p style={{ color: "red" }}>{FaultCodeError}</p>
                                    )}
                                </div>

                                {/* Submit Button */}
                                <div className='col-md-12 mb-3 row'>
                                    <div className='col-md-12' style={{ textAlign: "center" }}>
                                        <button
                                            title="Submit"
                                            className="btn btn-primary"
                                            style={{
                                                boxShadow: '0px 0px 5px 0px #4f4f4f',
                                                borderRadius: '15px',
                                                padding: '5px 20px',
                                            }}
                                            onClick={HandleSubmit}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                            : null
                        }

                        <div className="row my-3 horizontal-scroll mx-3">
                            <div className="col-md-6 block">
                                <h6>Resolution Codes</h6>
                                <ul>
                                    {ModuleData.ResolutionCode &&
                                        ModuleData.ResolutionCode.map((code, index) => (
                                            <li key={`resolution-${index}`}>{code}</li>
                                        ))}
                                </ul>
                            </div>
                            <div className="col-md-6 block">
                                <h6>Fault Codes</h6>
                                <ul>
                                    {ModuleData.FaultCode &&
                                        ModuleData.FaultCode.map((code, index) => (
                                            <li key={`fault-${index}`}>{code}</li>
                                        ))}
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default ResolveDetails