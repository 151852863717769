import React, { useEffect, useState } from "react";
import axios from "../../axios";
import Loader from "../../Components/Loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import DefaultLogo from "../../Images/foxilogic-disable.png"
import ReactCountryFlag from "react-country-flag"
import Switch from "react-switch";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import { REPORTTYPE, OPENGROUPTICKETSTATUSARR, CLOSEDGROUPTICKETSTATUSARR, CUSTOM_CLIENT_SELECT, CUSTOM_DATE_RANGE_OPTION, REPORT_DATEFORMAT } from '../../Custom';
import CabisensePage1 from "./CabisensePage1";
import CabisensePage2 from "./CabisensePage2";
import CabisenseDetailsPopUp from "./CabisenseDetailsPopUp";

const CheckOption = (props) => {
    return (
        <div className="custom-select-option">
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />
                &nbsp;
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

const CabisenseTicketTimeReport = () => {
    var UserGroup = JSON.parse(localStorage.getItem("UserGroup"));
    const location = useLocation();
    var url = new URL(window.location.href);
    const page = url.searchParams.get('PageNo') || 1
    const limit = url.searchParams.get('Limit') || 1000
    const search = url.searchParams.get('Search') || ''

    const navigate = useNavigate();

    const client_id = url.searchParams.get('ClientId') || '';
    const country_id = url.searchParams.get('ClientCountryId') || '';

    const incident_dispatched_flag = url.searchParams.get('IncidentDispatchedTime') || 0;
    const date_range = url.searchParams.get('DateRange') || "Last 7 Days";
    const maintenance = url.searchParams.get('Maintenance') || "";
    const report_type = url.searchParams.get('ReportType') || "Open Report";

    const [loader, setLoader] = useState(false);
    const [Clients, setClients] = useState([]);

    const [Client, setClient] = useState(localStorage.getItem("ClientId") > 0 ? localStorage.getItem("ClientId") : client_id);
    const [ModuleData, setModuleData] = useState([]);
    const [CountryList, setCountryList] = useState([])
    const [CountryID, setCountryID] = useState(localStorage.getItem("ClientCountryId") > 0 ? localStorage.getItem("ClientCountryId") : country_id)

    const [NoOfRecords, setNoOfRecords] = useState(0);
    const [searched, setSearched] = useState(false)
    const [ServiceCall, setServiceCall] = useState(location.state ? false : true);
    const [DateRange, setDateRange] = useState(date_range);

    const [SelectedDate, setSelectedDate] = useState("");
    const [SelectedEndDate, setSelectedEndDate] = useState("");

    const [OpenAvg, setOpenAvg] = useState(0);
    const [IncidentDispatchedAvg, setIncidentDispatchedAvg] = useState(0);
    const [MTTRAvg, setMTTRAvg] = useState(0);
    const [TTDAvg, setTTDAvg] = useState(0);
    const [AgeingSlaAvg, setAgeingSlaAvg] = useState(0);
    const [AgeingActualAvg, setAgeingActualAvg] = useState(0);
    // const [ResolvedAvg, setResolvedAvg] = useState(0);
    const [DiferredAvg, setDiferredAvg] = useState(0);

    const [IncidentDispatchedFlag, setIncidentDispatchedFlag] = useState(incident_dispatched_flag);
    const [searchInput, setSearchInput] = useState(search)
    const [TicketStatusList, setTicketStatusList] = useState([])
    const ReportTypeList = REPORTTYPE
    const [ReportType, setReportType] = useState(report_type)

    const [TicketStatus, setTicketStatus] = useState([])

    const [MaintenanceList, setMaintenanceList] = useState([])
    const [Maintenance, setMaintenance] = useState(maintenance)

    const activetab = url.searchParams.get('ActiveTab') || "Page1";
    const [ActiveTab, setActiveTab] = useState(activetab)

    const [AnalyticalData, setAnalyticalData] = useState({})

    const [Page1DetailsFlag, setPage1DetailsFlag] = useState("")
    const [Page1DetailsData, setPage1DetailsData] = useState([])
    const [IncidentCodeList, setIncidentCodeList] = useState([]);
    var UrlIncidentCode = []
    if (url.searchParams.get('IncidentCode')) {

        var Codes = url.searchParams.get('IncidentCode').split(",");
        for (var j = 0; j < Codes.length; j++) {
            for (var i = 0; i < IncidentCodeList.length; i++) {
                if (Codes[j] === IncidentCodeList[i].IncidentCode) {
                    var obj = IncidentCodeList[i]
                    UrlIncidentCode.push(obj);
                }
            }
        }
    }

    const [IncidentCode, setIncidentCode] = useState(UrlIncidentCode)
    const [RegionList, setRegionList] = useState([])
    const [Region, setRegion] = useState(url.searchParams.get('Region') || "")

    const [SiteHostList, setSiteHostList] = useState([])
    const [SiteHost, setSiteHost] = useState(url.searchParams.get('SiteHost') || "")

    const customsp = url.searchParams.get('CustomSp') || "0";
    const [CustomSp, setCustomSp] = useState(customsp);
    const [CustomSpList, setCustomSpList] = useState([])


    useEffect(() => {
        if (DateRange !== "") {
            if (DateRange === "Today") {

                const date = REPORT_DATEFORMAT(new Date());
                setSelectedDate(date);
                setSelectedEndDate(date);

            } else if (DateRange === "Current Week") {
                const today = new Date();
                var currentDay = today.getDay();
                var diff = today.getDate() - currentDay + (currentDay === 1 ? 0 : (currentDay === 0 ? -6 : 1)); // adjust when Sunday is the first day of the week
                var startOfWeek = new Date(today.setDate(diff));
                setSelectedDate(REPORT_DATEFORMAT(startOfWeek));

                var endOfWeek = new Date(startOfWeek);
                endOfWeek = new Date(endOfWeek.setDate(startOfWeek.getDate() + 6));
                setSelectedEndDate(REPORT_DATEFORMAT(endOfWeek));

            } else if (DateRange === "Last 7 Days") {

                const today = new Date();
                var sevenDaysAgo = new Date(today);
                sevenDaysAgo.setDate(today.getDate() - 6); // Subtract 6 to get 7 days ago
                sevenDaysAgo = REPORT_DATEFORMAT(sevenDaysAgo)
                setSelectedDate(sevenDaysAgo);
                setSelectedEndDate(REPORT_DATEFORMAT(today));

            } else if (DateRange === "Current Month") {

                const today = new Date();
                var startDate1 = new Date(today.getFullYear(), today.getMonth(), 1);
                var endDate1 = new Date(today.getFullYear(), today.getMonth() + 1, 0);
                startDate1 = REPORT_DATEFORMAT(startDate1);
                endDate1 = REPORT_DATEFORMAT(endDate1);
                setSelectedDate(startDate1);
                setSelectedEndDate(endDate1);

            } else if (DateRange === "Last Month") {
                const today = new Date();
                const startLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
                // Calculate the last day of the last month
                const endLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);

                setSelectedDate(REPORT_DATEFORMAT(startLastMonth));
                setSelectedEndDate(REPORT_DATEFORMAT(endLastMonth));


            } else if (DateRange === "Last 6 Months") {

                const today = new Date();
                var lastSixMonthsDate = new Date(today.getFullYear(), today.getMonth() - 6, today.getDate());
                lastSixMonthsDate = REPORT_DATEFORMAT(lastSixMonthsDate);
                setSelectedDate(lastSixMonthsDate);
                setSelectedEndDate(REPORT_DATEFORMAT(today));

            } else if (DateRange === "Custom") {

                const date = REPORT_DATEFORMAT(new Date());
                setSelectedDate(date);
                setSelectedEndDate(date);
            } else if (DateRange === "All") {
                setSelectedDate("");
                setSelectedEndDate("");
            }
            setSearched(true);
        }

    }, [DateRange])

    useEffect(() => {
        if (ReportType !== "" && TicketStatusList.length > 0) {
            var Arr = [];
            if (ReportType === "Open Report") {
                for (var i = 0; i < TicketStatusList.length; i++) {
                    if (OPENGROUPTICKETSTATUSARR.includes(TicketStatusList[i].value)) {
                        Arr.push(TicketStatusList[i]);
                    }
                }
            } else if (ReportType === "Closed Report") {
                for (var j = 0; j < TicketStatusList.length; j++) {
                    if (CLOSEDGROUPTICKETSTATUSARR.includes(TicketStatusList[j].value)) {
                        Arr.push(TicketStatusList[j]);
                    }
                }

            } else if (ReportType === "OverAll Report") {
                for (var k = 0; k < TicketStatusList.length; k++) {
                    if (TicketStatusList[k].value !== "Resolved") {
                        Arr.push(TicketStatusList[k]);
                    }
                }
            }

            setTicketStatus(Arr);
            setSearched(true);
        }

    }, [ReportType, TicketStatusList])

    useEffect(() => {
        if ((UserGroup.Id === 1 || UserGroup.Id === 2 || UserGroup.Id === 3)) {
            setLoader(true);
            axios
                .get("get_clients")
                .then((response) => {
                    var TempData = response.data.data;
                    var arr = [];
                    for (var i = 0; i < TempData.length; i++) {
                        var obj = TempData[i]
                        obj["label"] = TempData[i].Name;
                        obj["value"] = TempData[i].Id;
                        obj["image"] = TempData[i].ClientLogo ? TempData[i].ClientLogo : DefaultLogo;
                        arr.push(obj)
                    }
                    setClients(arr)
                    setLoader(false);
                })
                .catch(() => {
                    setLoader(false);
                });
        }
    }, [UserGroup.Id]);

    useEffect(() => {
        if (CountryID) {
            setLoader(true)

            axios.get("masters?ClientCountryId=" + CountryID)
                .then((response) => {
                    setTicketStatusList(response.data.data.TicketStatus)
                    var Temp = response.data.data.TicketStatus;
                    var Arr = [];
                    for (var i = 0; i < Temp.length; i++) {
                        var Obj = {};
                        Obj['value'] = Temp[i];
                        Obj['label'] = Temp[i];
                        Arr.push(Obj);
                    }
                    setTicketStatusList(Arr);

                    var CabisenseIncidentCodes = response.data.data.CabisenseIncidentCodes;
                    var CabisenseIncidentArr = [];
                    for (var j = 0; j < CabisenseIncidentCodes.length; j++) {
                        var CabisenseIncidentObj = CabisenseIncidentCodes[j];
                        CabisenseIncidentObj['value'] = CabisenseIncidentCodes[j].IncidentCode;
                        CabisenseIncidentObj['label'] = CabisenseIncidentCodes[j].IncidentCode;
                        CabisenseIncidentArr.push(CabisenseIncidentObj);
                    }
                    setIncidentCodeList(CabisenseIncidentArr);

                    setMaintenanceList(response.data.data.aSiteMaintenanceNames)
                    setRegionList(response.data.data.SigfoxSiteRegions)
                    setSiteHostList(response.data.data.AllSiteHosts)
                    setCustomSpList(response.data.data.CountryWiseCustomSpList)

                    setLoader(false)

                })
                .catch((error) => {
                    setLoader(false)

                })
        }

    }, [CountryID])

    const getClientValue = () => {
        if (Client) {
            for (var i = 0; i < Clients.length; i++) {
                if (Clients[i].Id === parseInt(Client)) {
                    return [{ value: Clients[i].Id, label: Clients[i].Name, image: Clients[i].image }];
                }
            }
        }
    }

    const OnchangeClient = (selected) => {
        setClient(selected.Id)
        setCountryList([]);
        setModuleData([])
        setCountryID("");
        navigate('/report/cabisense_ticket_time_report')
    }

    useEffect(() => {
        for (var i = 0; i < Clients.length; i++) {
            if (Clients[i].Id === parseInt(Client)) {
                setCountryList(Clients[i].countries)
                break
            }
        }
    }, [Client, Clients]);

    useEffect(() => {
        if (searched && Client && CountryID) {
            var Str = []
            for (let i = 0; i < TicketStatus.length; i++) {
                Str += TicketStatus[i].value
                if (i !== TicketStatus.length - 1) {
                    Str += "-";
                }
            }

            var IncidentCodeStr = []
            for (let i = 0; i < IncidentCode.length; i++) {
                IncidentCodeStr += IncidentCode[i].IncidentCode
                if (i !== IncidentCode.length - 1) {
                    IncidentCodeStr += ",";
                }
            }

            navigate("?Search=" + searchInput + "&page=" + page + "&Limit=" + limit + "&ClientId=" + Client + "&ClientCountryId=" + CountryID + "&StartDate=" + SelectedDate + "&EndDate=" + SelectedEndDate + "&IncidentDispatchedTime=" + IncidentDispatchedFlag + "&Status=" + Str + "&DateRange=" + DateRange + "&Maintenance=" + Maintenance + "&ReportType=" + ReportType + "&ActiveTab=" + ActiveTab + "&IncidentCode=" + IncidentCodeStr + "&Region=" + Region + "&SiteHost=" + SiteHost)
            setServiceCall(true);
            setSearched(false)
        }
    }, [navigate, searched, Client, CountryID, SelectedDate, SelectedEndDate, IncidentDispatchedFlag, page, limit, searchInput, TicketStatus, DateRange, Maintenance, ReportType, ActiveTab, IncidentCode, Region, SiteHost])

    useEffect(() => {
        if (Client && CountryID && ServiceCall && DateRange !== "" && TicketStatus.length > 0) {

            const TimeZone = localStorage.getItem("TimeZone") ? JSON.parse(localStorage.getItem("TimeZone")) : Intl.DateTimeFormat().resolvedOptions().timeZone
            const TimeZoneParm = (typeof TimeZone === 'string') ? TimeZone : TimeZone.value;

            // setTotalTicketCount(0);
            setAnalyticalData({})
            setModuleData([])
            setOpenAvg(0)
            setIncidentDispatchedAvg(0)
            setMTTRAvg(0)
            setTTDAvg(0)
            // setResolvedAvg(0)
            setAgeingSlaAvg(0)
            setAgeingActualAvg(0)
            setDiferredAvg(0)
            setLoader(true);

            var Arr = []
            for (let i = 0; i < TicketStatus.length; i++) {
                Arr.push(TicketStatus[i].value);
            }

            var IncidentCodeArr = []
            for (let i = 0; i < IncidentCode.length; i++) {
                IncidentCodeArr.push(IncidentCode[i].IncidentCode);
            }

            axios
                .get("report/cabisense_ticket_time_report?Search=" + searchInput + "&PageNo=" + page + "&Limit=" + limit + "&ClientCountryId=" + CountryID + "&StartDate=" + SelectedDate + "&EndDate=" + SelectedEndDate + "&IncidentDispatchedTime=" + IncidentDispatchedFlag + "&Status=" + Arr + "&Timezone=" + TimeZoneParm + "&SiteMaintenanceName=" + Maintenance + "&IncidentCode=" + IncidentCodeArr + "&Region=" + Region + "&SiteHost=" + SiteHost + "&CustomSp=" + CustomSp)
                .then((response) => {
                    setNoOfRecords(response.data.NoOfRecords)
                    setModuleData(response.data.data.data)
                    setOpenAvg(response.data.data.TotalOpenDiff)
                    setIncidentDispatchedAvg(response.data.data.TotalIncidentDispatchedDiff)
                    setMTTRAvg(response.data.data.TotalTtrDiff)
                    setTTDAvg(response.data.data.TotalTtdDiff)
                    setAgeingSlaAvg(response.data.data.TotalAgingSlaDiff)
                    setAgeingActualAvg(response.data.data.TotalAgingActualDiff)
                    // setTotalTicketCount(response.data.data.TotalTicketCount)
                    setDiferredAvg(response.data.data.TotalDiferredDiff)

                    setAnalyticalData({
                        "TotalOpenTicketCounts": response.data.data.TotalOpenTicketCounts,
                        "OpenTicketCounts": response.data.data.OpenTicketCounts,
                        "IncidentDispatchedTicketCounts": response.data.data.IncidentDispatchedTicketCounts,
                        "DeferredTicketCounts": response.data.data.DeferredTicketCounts,
                        "TotalTtdDiff": response.data.data.TotalTtdDiff,
                        "TotalTtrDiff": response.data.data.TotalTtrDiff,
                        "TotalClosedTicketCounts": response.data.data.TotalClosedTicketCounts,

                        "TwoDaysAgeingSlaCounts": response.data.data.TwoDaysAgeingSlaCounts,
                        "TwoToFiveDaysAgeingSlaCounts": response.data.data.TwoToFiveDaysAgeingSlaCounts,
                        "GreaterFiveAgeingSlaCounts": response.data.data.GreaterFiveAgeingSlaCounts,

                        "TwoDaysAgeingActualCounts": response.data.data.TwoDaysAgeingActualCounts,
                        "TwoToFiveDaysAgeingActualCounts": response.data.data.TwoToFiveDaysAgeingActualCounts,
                        "GreaterFiveAgeingActualCounts": response.data.data.GreaterFiveAgeingActualCounts
                    })
                    setServiceCall(false)
                    setLoader(false);
                })
                .catch(() => {
                    setLoader(false);
                });
        }

    }, [ServiceCall, Client, CountryID, SelectedDate, SelectedEndDate, IncidentDispatchedFlag, page, limit, searchInput, TicketStatus, Maintenance, DateRange, IncidentCode, Region, SiteHost, CustomSp])


    const handleExportTicket = () => {
        setLoader(true)
        const TimeZone = localStorage.getItem("TimeZone") ? JSON.parse(localStorage.getItem("TimeZone")) : Intl.DateTimeFormat().resolvedOptions().timeZone
        const TimeZoneParm = (typeof TimeZone === 'string') ? TimeZone : TimeZone.value
        var Arr = []
        for (let i = 0; i < TicketStatus.length; i++) {
            Arr.push(TicketStatus[i].value);
        }

        var IncidentCodeArr = []
        for (let i = 0; i < IncidentCode.length; i++) {
            IncidentCodeArr.push(IncidentCode[i].IncidentCode);
        }

        var url = ""
        if (Page1DetailsFlag !== "") {
            url = "report/export_cabisense_ticket_time_report?Search=" + searchInput + "&PageNo=" + page + "&Limit=" + limit + "&ClientCountryId=" + CountryID + "&StartDate=" + SelectedDate + "&EndDate=" + SelectedEndDate + "&IncidentDispatchedTime=" + IncidentDispatchedFlag + "&Timezone=" + TimeZoneParm + "&Status=" + Arr + "&SiteMaintenanceName=" + Maintenance + "&OpenGroupTicketStatus=" + Page1DetailsFlag + "&IncidentCode=" + IncidentCodeArr + "&Region=" + Region + "&SiteHost=" + SiteHost + "&CustomSp=" + CustomSp
        } else {
            url = "report/export_cabisense_ticket_time_report?Search=" + searchInput + "&PageNo=" + page + "&Limit=" + limit + "&ClientCountryId=" + CountryID + "&StartDate=" + SelectedDate + "&EndDate=" + SelectedEndDate + "&IncidentDispatchedTime=" + IncidentDispatchedFlag + "&Timezone=" + TimeZoneParm + "&Status=" + Arr + "&SiteMaintenanceName=" + Maintenance + "&IncidentCode=" + IncidentCodeArr + "&Region=" + Region + "&SiteHost=" + SiteHost + "&CustomSp=" + CustomSp
        }

        axios
            .get(url)
            .then((response) => {
                var link = document.createElement("a");
                link.target = "_blank";
                link.href = response.data.data;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setLoader(false)
                toast.success(response.data.status.message)
            })
            .catch((error) => {
                setLoader(false)
            })
    }


    useEffect(() => {
        setServiceCall(true);
    }, [limit, page, search]);

    const clearSearchHandler = () => {
        setServiceCall(true)
        setSearched(false)
        setSearchInput("")
        // setTicketStatus([])
        setDateRange("Last 7 Days");
        setIncidentDispatchedFlag(0)
        setMaintenance("")
        setIncidentCode([]);
        setReportType("Open Report");
        setRegion("")
        setSiteHost("")
        setCustomSp("0")
        navigate("?Search= &page=&ClientId=" + Client + "&ClientCountryId=" + CountryID + "&StartDate=&EndDate=&IncidentDispatchedTime=0&Status=&DateRange=Last 7 Days&Maintenance=&ReportType=Open Report&CustomSp=0")
    }

    const HandleDetailsPopUp = (OpenGroupTicketStatus) => {
        const TimeZone = localStorage.getItem("TimeZone") ? JSON.parse(localStorage.getItem("TimeZone")) : Intl.DateTimeFormat().resolvedOptions().timeZone
        const TimeZoneParm = (typeof TimeZone === 'string') ? TimeZone : TimeZone.value;

        // setLoader(true);
        var Arr = []
        for (let i = 0; i < TicketStatus.length; i++) {
            Arr.push(TicketStatus[i].value);
        }

        setLoader(true);

        setPage1DetailsData([])

        var IncidentCodeArr = []
        for (let i = 0; i < IncidentCode.length; i++) {
            IncidentCodeArr.push(IncidentCode[i].IncidentCode);
        }

        axios
            .get("report/get_cabisense_open_group_tickets_list?Search=" + searchInput + "&ClientCountryId=" + CountryID + "&StartDate=" + SelectedDate + "&EndDate=" + SelectedEndDate + "&IncidentDispatchedTime=" + IncidentDispatchedFlag + "&Status=" + Arr + "&Timezone=" + TimeZoneParm + "&SiteMaintenanceName=" + Maintenance + "&OpenGroupTicketStatus=" + OpenGroupTicketStatus + "&IncidentCode=" + IncidentCodeArr + "&Region=" + Region + "&SiteHost=" + SiteHost + "&CustomSp=" + CustomSp)
            .then((response) => {
                setPage1DetailsData(response.data.data)
                setPage1DetailsFlag(OpenGroupTicketStatus)
                setLoader(false);
            })
            .catch(() => {
                setLoader(false);
            });
    }

    return (
        <>
            <ToastContainer theme="colored" />
            {loader ? <Loader /> : null}
            {Page1DetailsFlag !== "" ? <CabisenseDetailsPopUp handleExportTicket={handleExportTicket} setPage1DetailsFlag={setPage1DetailsFlag} Page1DetailsFlag={Page1DetailsFlag} Page1DetailsData={Page1DetailsData} /> : ""}
            <div className="row">

                <div className="col-xxl-4">
                    <h6 className="mt-2">Cabisense Ticket Time Report</h6>
                </div>


                <div className="col-xxl-8">
                    <div className="row justify-content-end">

                        {Client && CountryID ?
                            <div className="col-xxl-3">
                                <input
                                    type="text"
                                    className="form-control mb-2"
                                    id="search"
                                    name="search"
                                    title="Search Ticket Id, Basestation Id, Lessor Id, Site Name, Fault Reference Number"
                                    placeholder="Search Ticket Id ,  Basestation Id ,  Lessor Id,  Site Name, Fault Reference Number"
                                    value={searchInput}
                                    onKeyUp={(e) => {
                                        if (e.key === "Enter") {
                                            setSearched(true)
                                        }
                                    }}
                                    onChange={(e) => { setSearchInput(e.target.value) }}
                                />

                            </div>
                            : null}

                        {UserGroup.Id === 1 || UserGroup.Id === 2 ?
                            <div className="col-xxl-3" >
                                {CUSTOM_CLIENT_SELECT({ ClientListOptions: Clients, Value: getClientValue(), OnchangeClient: OnchangeClient })}
                            </div>
                            : null}


                        {((UserGroup.Id === 1 || UserGroup.Id === 2 || UserGroup.Id === 3) && CountryList.length > 0 && Client) ?
                            <div className="col-xxl-3" >
                                <select
                                    className="form-select mb-3"
                                    onChange={(e) => {
                                        setCountryID(e.target.value)
                                        setSearched(true);
                                        // setServiceCall(true);
                                    }}
                                    value={CountryID}
                                >
                                    <option value="">Select Country</option>
                                    {
                                        CountryList.map((item, index) => (
                                            <option key={index} value={item.Id}>
                                                {item.CountryCode ?
                                                    <ReactCountryFlag
                                                        className="add_country_flag mx-5"
                                                        countryCode={item.CountryCode}
                                                        aria-label={item.CountryCode}
                                                        style={{
                                                        }}
                                                    />
                                                    : <i
                                                        className="bx bx-flag mx-1 p-0"
                                                        title="Country"
                                                        style={{ fontSize: "21px", cursor: "pointer" }}
                                                    ></i>
                                                }
                                                &nbsp;&nbsp;&nbsp;{item.CountryName}
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                            : null}

                        {Client && CountryID ?
                            <div className="col-xxl-3" >
                                <select
                                    className="form-select"
                                    onChange={(e) => {
                                        setCustomSp(e.target.value);
                                        setSearched(true);
                                    }}
                                    value={CustomSp}
                                >
                                    <option value="0">Select Service Provider</option>
                                    {CustomSpList.map((item) => {
                                        return (
                                            <option key={item.Id} value={item.Id}>
                                                {item.CustomSp}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div> : null
                        }
                    </div>
                </div>
            </div>


            <hr />
            <div className="row">

                {/* <div className="col-6 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-xxl-2">
                   
                </div> */}



                {Client && CountryID ?
                    <>
                        <div className="col-6 col-sm-4 col-md-4 col-lg-2 col-xl-2 col-xxl-1">
                            <select
                                className="form-select mb-3"
                                onChange={(e) => {
                                    setDateRange(e.target.value);
                                }}
                                value={DateRange}
                            >
                                {CUSTOM_DATE_RANGE_OPTION}
                            </select>
                        </div>
                        <div className="col-6 col-sm-4 col-md-4 col-lg-2 col-xl-1 col-xxl-1">
                            <input
                                type="date"
                                disabled={DateRange === "Custom" ? false : true}
                                className="form-control mb-3"
                                placeholder="Select Date"
                                value={SelectedDate}
                                onChange={(e) => {
                                    setSelectedDate(e.target.value)
                                    setSearched(true);
                                }}
                            />
                        </div>
                        <div className="col-6 col-sm-4 col-md-4 col-lg-2 col-xl-1 col-xxl-1">
                            <input
                                type="date"
                                disabled={DateRange === "Custom" ? false : true}
                                className="form-control mb-3"
                                placeholder="Select Date"
                                value={SelectedEndDate}
                                onChange={(e) => {
                                    setSelectedEndDate(e.target.value)
                                    setSearched(true);
                                }}
                            />
                        </div>
                        <div className="col-6 col-sm-4 col-md-4 col-lg-2 col-xl-2 col-xxl-2">
                            <select
                                className="form-select"
                                onChange={(e) => {
                                    if (e.target.value !== "") {
                                        setReportType(e.target.value)
                                    }
                                }}
                                value={ReportType}
                            >
                                <option value="">Select Report Type</option>

                                {
                                    ReportTypeList.map((item, index) => (
                                        <option key={index} value={item}> {item} </option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className="col-6 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-xxl-2">
                            <ReactSelect
                                options={TicketStatusList}
                                isMulti
                                placeholder='Select Ticket Status'
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                // defaultMenuIsOpen
                                components={{
                                    Option: CheckOption

                                }}
                                onChange={(selected) => {
                                    setTicketStatus(selected)
                                    setReportType("");
                                    setSearched(true)
                                }}
                                allowSelectAll={true}
                                value={TicketStatus ? TicketStatus : null}
                            />

                        </div>
                        <div className="col-6 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-xxl-5 row">
                            <div className="col-6 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                                <ReactSelect
                                    options={IncidentCodeList}
                                    isMulti
                                    closeMenuOnSelect={false}
                                    placeholder='Select Incident Code'
                                    hideSelectedOptions={false}
                                    // defaultMenuIsOpen
                                    components={{
                                        Option: CheckOption
                                    }}
                                    onChange={(selected) => {
                                        setIncidentCode(selected);
                                        setSearched(true)
                                    }}
                                    allowSelectAll={true}
                                    value={IncidentCode ? IncidentCode : null}
                                />

                            </div>
                            <div className="col-6 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                                <select
                                    className="form-select mb-3"
                                    onChange={(e) => {
                                        setSiteHost(e.target.value)
                                        setSearched(true);

                                    }}
                                    value={SiteHost}
                                >
                                    <option value="">Select Site Host</option>
                                    {
                                        SiteHostList.map((item, index) => (
                                            <option key={index} value={item}> {item} </option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className="col-6 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                                <select
                                    className="form-select"
                                    onChange={(e) => {
                                        setMaintenance(e.target.value)
                                        setSearched(true)
                                    }}
                                    value={Maintenance}
                                >
                                    <option value="">Select Maintenance</option>
                                    {
                                        MaintenanceList.map((item, index) => (
                                            <option key={index} value={item}> {item} </option>
                                        ))
                                    }
                                </select>

                            </div>
                            <div className="col-6 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                                <select
                                    className="form-select"
                                    onChange={(e) => {
                                        setRegion(e.target.value)
                                        setSearched(true)
                                    }}
                                    value={Region}
                                >
                                    <option value=""> Select Region</option>
                                    {
                                        RegionList.map((item, index) => (
                                            <option key={index} value={item}> {item} </option>
                                        ))
                                    }
                                </select>
                            </div>

                        </div>

                        {/* <div className="col-6 col-sm-4 col-md-4 col-lg-1 col-xl-2 col-xxl-1">
                           
                        </div> */}
                    </>
                    : null
                }
            </div>

            {Client && CountryID ? <div className="card">
                <div className="card-body">

                    <div className="row mb-4">
                        <div className="col-md-4 px-5 d-flex align-items-center">
                            <ul className="nav nav-tabs outage_nav_tabs" >
                                <li className="nav-item" onClick={() => {
                                    setActiveTab('Page1');
                                }} >
                                    <p className={(ActiveTab === "Page1" ? " nav-link active p-active" : "nav-link p")} aria-current="page" >Page 1</p>
                                </li>

                                <li className="nav-item" onClick={() => {
                                    setActiveTab('Page2');
                                }} >
                                    <p className={(ActiveTab === "Page2" ? " nav-link active p-active" : "nav-link p")} aria-current="page" >Page 2</p>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-4 d-flex justify-content-center align-items-center">
                            <p className="m-0"> Total Count :&nbsp; <b>  {NoOfRecords}</b></p>
                        </div>


                        <div className="col-md-4 text-right d-flex justify-content-end align-items-center">
                            <Switch
                                className="login-switch mx-1"
                                onColor={"#136dfa"}
                                height={25}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                onChange={(e) => {
                                    var Value = parseInt(IncidentDispatchedFlag) === 0 ? 1 : 0;
                                    setIncidentDispatchedFlag(Value)
                                    setSearched(true);
                                }
                                }
                                checked={parseInt(IncidentDispatchedFlag) === 0 ? false : true}
                            />
                            <p className="m-0">Show Incident Dispatch</p>

                            &nbsp;&nbsp;
                            {(searched || searchInput || parseInt(IncidentDispatchedFlag) === 1 || (DateRange !== "Last 7 Days") || (ReportType !== "Open Report")) || IncidentCode.length > 0 || Region || SiteHost || CustomSp ?
                                <button
                                    type="button"
                                    className="btn btn-primary m-2"
                                    title="Reset Filters"
                                    onClick={clearSearchHandler}
                                >
                                    <i className="bx bx-eraser search-icon"></i>
                                </button>
                                : null}

                            <button
                                type="button"
                                className="btn btn-primary"
                                title="Export"
                                onClick={handleExportTicket}
                                style={{ boxShadow: '0px 0px 5px 0px #4f4f4f', borderRadius: '15px', padding: '5px 20px' }}
                            >
                                Export &nbsp;
                            </button>
                        </div>
                    </div>

                    {
                        ActiveTab === "Page1" ? <CabisensePage1 AnalyticalData={AnalyticalData} HandleDetailsPopUp={HandleDetailsPopUp} /> : null
                    }

                    {
                        ActiveTab === "Page2" ?

                            <CabisensePage2 ModuleData={ModuleData} OpenAvg={OpenAvg} TTDAvg={TTDAvg} IncidentDispatchedAvg={IncidentDispatchedAvg} DiferredAvg={DiferredAvg} AgeingSlaAvg={AgeingSlaAvg} AgeingActualAvg={AgeingActualAvg} MTTRAvg={MTTRAvg} page={page} searchInput={searchInput} Client={Client} CountryID={CountryID} SelectedDate={SelectedDate} SelectedEndDate={SelectedEndDate} IncidentDispatchedFlag={IncidentDispatchedFlag} TicketStatus={TicketStatus} DateRange={DateRange} Maintenance={Maintenance} Region={Region} ReportType={ReportType} NoOfRecords={NoOfRecords} limit={limit} SiteHost={SiteHost} />

                            : null}

                </div>
            </div> : null}
        </>
    );
};

export default CabisenseTicketTimeReport;
