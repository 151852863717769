import axios from "axios";

const instance = axios.create({
  // baseURL: "http://103.86.177.42/~cpmain/foxi_logic_api/api/admin",
  // baseURL: "https://swtprime.com/foxi_logic_api/api/admin/",          //--------- Prime login
  // baseURL: "http://192.168.1.53:8000/api/admin/",                     //--------- Dipak Local ip url
  // baseURL: "http://192.168.1.44:8000/api/admin/",                     //--------- Ritali LocalG SWT 5G ip url
  // baseURL: "http://192.168.1.134:8000/api/admin/",                     //--------- Ritali Local STandardwing_5g ip url
  baseURL: "https://api.foxilogic.com/api/admin/",    //--------- Main server url
  // baseURL: "http://127.0.0.1:8000/api/admin/",                       //--------- Ritali Local dev url
  headers: {
    Accept: "application/json",
    Authorization: {
      toString() {
        return `Bearer ${localStorage.getItem("Token") ? JSON.parse(localStorage.getItem("Token")).token : ""}`;
      },
    },
  },
});

export default instance;
